import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { buildApiUrl } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import { SlackUser } from "../types/user";

// Import the components
import AccessControlTab from "../components/team/AccessControlTab";
import MembersTab from "../components/team/MembersTab";

// Types
interface TeamMember {
  id: string;
  email: string;
  name: string;
  role: "admin" | "editor" | "member";
  is_disabled: boolean;
  is_owner: boolean;
  avatar_url: string;
}

function TeamPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const tabParam = searchParams.get("tab");
  const api = useApi();

  const [members, setMembers] = useState<TeamMember[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState<
    "admin" | "editor" | "member"
  >("member");
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<"members" | "access">(
    tabParam === "access" ? "access" : "members",
  );

  // Fetch team members from the API and map to TeamMember format
  const fetchTeamMembers = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        buildApiUrl("/v2/slack/teammates", teamId),
      );

      // Map SlackUsers to TeamMembers
      const slackUsers: SlackUser[] = response.data;
      const mappedMembers: TeamMember[] = slackUsers.map((user) => ({
        id: user.id,
        email: user.email || "",
        // Use username if first_name and last_name aren't available
        name:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.username,
        // Owner should always be admin regardless of the role field
        // For regular users, use the role from the API
        role: user.is_owner ? "admin" : user.role || "member",
        // Set status based on disabled flag
        avatar_url: user.avatar_url,
        is_disabled: user.is_disabled,
        is_owner: user.is_owner,
      }));

      setMembers(mappedMembers);

      // Find the current user based on the is_current_user flag from the API
      // The backend now identifies which user is the currently authenticated user
      const currentUser = mappedMembers.find((member) => {
        // Find the corresponding API user to check the is_current_user flag
        const apiUser = slackUsers.find((u) => u.id === member.id);
        return apiUser && apiUser.is_current_user === true;
      });

      if (currentUser) {
        setCurrentUserRole(currentUser.role);
        setCurrentUserId(currentUser.id);
      } else {
        console.warn("Unable to identify current user from API response.");
      }
    } catch (err) {
      console.error("Error fetching teammates:", err);
      Sentry.captureException(err);
      toast.error("Failed to load team members", {
        position: "bottom-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch team members on component mount
  useEffect(() => {
    if (teamId) {
      fetchTeamMembers();
    }
  }, [teamId, api]);

  // Render Members tab using the MembersTab component
  const renderMembersTab = () => (
    <MembersTab
      members={members}
      isLoading={isLoading}
      teamId={teamId}
      onMemberUpdate={fetchTeamMembers} // Callback to refresh the member list after role changes
      handleRoleChange={handleRoleChange} // Pass parent role management function
      handleToggleUserStatus={handleToggleUserStatus} // Pass parent status management function
      currentUserRole={currentUserRole} // Pass the current user's role for permission checks
      currentUserId={currentUserId} // Pass current user ID to highlight current user
    />
  );

  // Handler for role changes
  const handleRoleChange = async (
    userId: string,
    newRole: "admin" | "editor" | "member",
  ) => {
    // Find the member and their current role before any changes
    const memberToUpdate = members.find((member) => member.id === userId);
    if (!memberToUpdate) {
      toast.error("User not found", { position: "bottom-center" });
      return;
    }

    const originalRole = memberToUpdate.role;

    try {
      // Update UI immediately for better user experience
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === userId ? { ...member, role: newRole } : member,
        ),
      );

      // Send API request to update the role
      // The API endpoint is a POST endpoint according to the API definition
      await api.post(buildApiUrl(`/v2/slack/users/${userId}/role`, teamId), {
        role: newRole,
      });

      toast.success(`User role updated to ${newRole}`, {
        position: "bottom-center",
      });
    } catch (err) {
      console.error("Error updating user role:", err);
      Sentry.captureException(err);

      // Revert the UI change on error, going back to the original role
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === userId
            ? {
                ...member,
                role: originalRole, // Return to the original role
              }
            : member,
        ),
      );

      toast.error("Failed to update user role", { position: "bottom-center" });
    }
  };

  // Handler for toggling user status (enable/disable)
  const handleToggleUserStatus = async (
    userId: string,
    newStatus: "active" | "deactivated",
  ) => {
    try {
      // Update UI immediately for better user experience
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === userId
            ? { ...member, is_disabled: newStatus === "deactivated" }
            : member,
        ),
      );

      // Send API request to update the user status
      // The API endpoint is a POST endpoint according to the API definition
      await api.post(buildApiUrl(`/v2/slack/users/${userId}/status`, teamId), {
        disabled: newStatus === "deactivated",
      });

      toast.success(
        `User ${newStatus === "active" ? "enabled" : "disabled"} successfully`,
        { position: "bottom-center" },
      );
    } catch (err) {
      console.error("Error updating user status:", err);
      Sentry.captureException(err);

      // Revert the UI change on error
      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === userId
            ? {
                ...member,
                is_disabled: !member.is_disabled, // Toggle the disabled state
              }
            : member,
        ),
      );

      toast.error("Failed to update user status", {
        position: "bottom-center",
      });
    }
  };

  // Render Access Control tab using the extracted component
  const renderAccessControlTab = () => <AccessControlTab teamId={teamId} />;

  return (
    <div className="container mx-auto">
      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-5">
            <h2 className="text-2xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon icon={faUsers} className="mr-3 text-brand" />
              Team Management
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              Manage your team members and access controls
            </p>
          </div>
          <div className="border-t border-gray-200">
            <div className="flex">
              <button
                className={`px-6 py-3 font-medium text-sm ${
                  activeTab === "members"
                    ? "text-brand border-b-2 border-brand"
                    : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 border-transparent"
                }`}
                onClick={() => {
                  setActiveTab("members");
                  // Update URL query parameter
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set("tab", "members");
                  setSearchParams(newParams);
                }}
              >
                Team Members
              </button>
              <button
                className={`px-6 py-3 font-medium text-sm ${
                  activeTab === "access"
                    ? "text-brand border-b-2 border-brand"
                    : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 border-transparent"
                }`}
                onClick={() => {
                  setActiveTab("access");
                  // Update URL query parameter
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set("tab", "access");
                  setSearchParams(newParams);
                }}
              >
                Access Control
              </button>
            </div>
          </div>
        </div>

        {activeTab === "members"
          ? renderMembersTab()
          : renderAccessControlTab()}
      </div>
    </div>
  );
}

export default TeamPage;
