import React from "react";

interface LoadingOverlayProps {
  isLoading: boolean;
  message?: string;
  children: React.ReactNode;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
  message = "Processing...",
  children,
}) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <div className="relative">
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-white bg-opacity-80 z-10 flex flex-col items-center justify-center">
        <div className="animate-spin inline-block w-8 h-8 border-2 border-current border-t-transparent text-brand rounded-full mb-4"></div>
        <p className="text-gray-600">{message}</p>
      </div>

      {/* Original content (blurred/dimmed) */}
      <div className="blur-sm opacity-30 pointer-events-none">{children}</div>
    </div>
  );
};

export default LoadingOverlay;
