import { useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import Card from "../components/card";
import { useUserProfile } from "../hooks/useUserProfile";
import { toast } from "react-toastify";
import ContentGrouped from "../components/content/contentCard";
import Spinner from "../components/spinner";
import LoadingOverlay from "../components/loadingOverlay";
import Autocomplete from "../components/autocomplete";

import { ThisOrThatCreateForm } from "../components/create/ThisOrThatCreateForm";
import { PicturePuzzleCreateForm } from "../components/create/PicturePuzzleCreateForm";
import { TriviaCreateForm } from "../components/create/TriviaCreateForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";

import {
  CUSTOMIZATION_GAME_CONFIGS as GAME_CONFIGS,
  THIS_OR_THAT,
  PICTURE_PUZZLE,
  TRIVIA,
} from "../constants";

import { validateContentRow } from "../lib/forms";

import { GameName, Category, Channel, Scope, ContentRow } from "../types/games";

const GameContentCreator = () => {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const api = useApi();
  const { isPremium, isLoading: isUserProfileLoading } = useUserProfile();
  const navigate = useNavigate();

  const [showCreator, setShowCreator] = useState(false);
  const [content, setContent] = useState<
    Array<{
      id: string;
      content_type: string;
      category?: {
        id: string;
        name: string;
        human_name: string;
      };
      channel?: {
        id: string;
        name: string;
      };
      data: {
        choice_1?: string;
        choice_2?: string;
        question?: string;
        answer?: string;
        options?: string;
        clue?: string;
        given?: string;
        image_id?: string;
      };
      created: string;
      modified: string;
      is_one_off: boolean;
    }>
  >([]);

  const [contentLoading, setContentLoading] = useState(true);

  const [selectedGame, setSelectedGame] = useState<GameName | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [scope, setScope] = useState<Scope>("workspace");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [contentRows, setContentRows] = useState<ContentRow[]>([
    { id: Date.now() },
  ]);

  const fetchContent = useCallback(async () => {
    try {
      const response = await api.get(
        `/v2/games/library/content?team=${teamId}`,
      );
      setContent(response.data);
    } catch (error) {
      console.error("Failed to fetch content:", error);
    } finally {
      setContentLoading(false);
    }
  }, [api, teamId]);

  const fetchData = useCallback(async () => {
    try {
      const [categoriesRes, channelsRes] = await Promise.all([
        api.get(`/v2/games/categories?team=${teamId}`),
        api.get(`/v2/slack/channels?team=${teamId}`),
      ]);
      setCategories(categoriesRes.data);
      setChannels(channelsRes.data);
      setPageLoading(false);
    } catch (error) {
      console.error("Failed to fetch:", error);
      setPageLoading(false);
    }
  }, [api, teamId]);

  useEffect(() => {
    fetchData();
    fetchContent();
  }, [teamId, api, fetchContent, fetchData]);

  const resetForm = () => {
    setContentRows([{ id: Date.now() }]);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedGame) return;

    if (!selectedCategory) {
      toast.error("Please select a category");
      return;
    }

    // Use extracted validation
    const validationErrors = contentRows.map((row) =>
      validateContentRow(row, selectedGame),
    );
    const hasErrors = validationErrors.some((result) => !result.isValid);

    if (hasErrors) {
      // Show first error
      const firstError = validationErrors.find((result) => !result.isValid);
      toast.error(firstError?.error || "Please fix form errors");
      return;
    }

    setIsLoading(true);
    try {
      if (contentRows.length > 0) {
        const payload = {
          category_id: selectedCategory,
          channel_id: scope === "channel" ? selectedChannel : null,
          is_one_off: false,
          items: contentRows.map((row) => {
            if (selectedGame === THIS_OR_THAT) {
              return {
                content_type: "this-or-that",
                data: {
                  choice_1: row.choice_1,
                  choice_2: row.choice_2,
                },
              };
            }
            if (selectedGame === TRIVIA) {
              return {
                content_type: "trivia",
                data: {
                  question: row.question,
                  answer: row.answer,
                  options: [1, 2, 3, 4]
                    .map((n) => row[`option${n}`] || "")
                    .filter(Boolean)
                    .join(","),
                },
              };
            } else if (selectedGame === PICTURE_PUZZLE) {
              return {
                content_type: "picture-puzzle",
                data: {
                  answer: row.answer,
                  clue: row.clue,
                  given: row.given || row.answer,
                  image_id: row.image_id,
                },
              };
            }
            return null;
          }),
        };
        console.log("submitting", payload);

        await api.post(`/v2/games/library/bulk?team=${teamId}`, payload);
      } else {
        // Rest of your existing submission logic
      }
      await fetchContent();
      toast.success("Content added successfully");
      resetForm();
      setShowCreator(false);
    } catch {
      toast.error("Failed to add content");
    } finally {
      setIsLoading(false);
    }
  };

  const renderGameSelector = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {Object.entries(GAME_CONFIGS).map(([key, config]) => (
        <button
          key={key}
          onClick={() => {
            setSelectedGame(key as GameName);
            setContentRows([{ id: Date.now() }]);
          }}
          className={`p-4 rounded-lg border-2 ${
            selectedGame === key
              ? "border-brand bg-brandLight"
              : "border-gray-200 hover:border-brand"
          }`}
        >
          <h3 className="font-semibold mb-2">
            {config.icon} {config.title}
          </h3>
          <p className="text-sm text-gray-600">{config.description}</p>
        </button>
      ))}
    </div>
  );

  const renderCategorySelector = () => (
    <div className="space-y-2">
      <div className="flex justify-end items-center">
        {categories.length > 0 && (
          <span className="text-xs text-gray-600">
            {categories.length} categories available
          </span>
        )}
      </div>

      {categories.length === 0 ? (
        <div className="bg-gray-50 rounded-md p-4 space-y-4">
          <div className="flex gap-3">
            <div>
              <h3 className="text-sm font-medium text-gray-900">
                No categories available
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Create your first category to start adding content
              </p>
            </div>
          </div>

          <div className="flex gap-2">
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand text-sm"
            />
            <button
              type="button"
              onClick={async () => {
                if (!newCategoryName.trim()) {
                  toast.error("Please enter a category name");
                  return;
                }
                try {
                  const res = await api.post(`/v2/games/categories`, {
                    team: teamId,
                    name: newCategoryName.trim(),
                  });
                  setCategories([...categories, res.data]);
                  setSelectedCategory(res.data.id);
                  setNewCategoryName("");
                  toast.success("Category created successfully");
                } catch {
                  toast.error("Failed to create category");
                }
              }}
              className="px-4 py-2 bg-brand text-white rounded-md hover:bg-brandCompliment transition-colors duration-150 whitespace-nowrap flex items-center gap-1 text-sm"
            >
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Create Category
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          {!showNewCategoryInput ? (
            <div className="flex space-x-2">
              <div className="relative flex-grow">
                <Autocomplete
                  items={categories}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  getOptionLabel={(category) =>
                    category.human_name || category.name
                  }
                  getOptionValue={(category) => category.id}
                  placeholder="Select a category"
                  emptyMessage="No matching categories found"
                  clearable={true}
                  onCreateNew={() => {
                    setShowNewCategoryInput(true);
                    setSelectedCategory("");
                  }}
                  createNewLabel="Create new category"
                />
              </div>
            </div>
          ) : (
            <div className="flex space-x-2">
              <input
                type="text"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                placeholder="Enter new category name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
              />
              <button
                type="button"
                onClick={async () => {
                  if (!newCategoryName.trim()) {
                    toast.error("Please enter a category name");
                    return;
                  }
                  try {
                    const res = await api.post(`/v2/games/categories`, {
                      team: teamId,
                      name: newCategoryName.trim(),
                    });
                    setCategories([...categories, res.data]);
                    setSelectedCategory(res.data.id);
                    setNewCategoryName("");
                    setShowNewCategoryInput(false);
                    toast.success("Category created successfully");
                  } catch {
                    toast.error("Failed to create category");
                  }
                }}
                className="px-4 py-2 bg-brand text-white rounded-md hover:bg-brandCompliment transition-colors duration-150 whitespace-nowrap flex items-center gap-1"
              >
                Create
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowNewCategoryInput(false);
                  setNewCategoryName("");
                }}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-150"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderScopeSelector = () => (
    <div className="pl-4 border-l-2 border-gray-100 space-y-4">
      <div>
        <div className="flex gap-4">
          <button
            type="button"
            onClick={() => {
              setScope("workspace");
              setSelectedChannel("");
            }}
            className={`px-4 py-2 rounded-md flex items-center gap-2 ${
              scope === "workspace"
                ? "bg-brand text-white shadow-sm"
                : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-300"
            }`}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            Entire Workspace
          </button>
          <button
            type="button"
            onClick={() => setScope("channel")}
            className={`px-4 py-2 rounded-md flex items-center gap-2  ${
              scope === "channel"
                ? "bg-brand text-white shadow-sm"
                : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-300"
            }`}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
              />
            </svg>
            Specific Channel
          </button>
        </div>
      </div>

      {scope === "channel" && (
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700">
              Channel
            </label>
            {channels.length > 0 && (
              <span className="text-xs text-gray-600">
                {channels.length} channels available
              </span>
            )}
          </div>

          {channels.length === 0 ? (
            <div className="bg-gray-50 rounded-md p-4">
              <div className="flex gap-3">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    No channels available
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Add the Braid Bot to channels in Slack to make them
                    available here
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="relative">
                <Autocomplete
                  items={channels}
                  value={selectedChannel}
                  onChange={setSelectedChannel}
                  getOptionLabel={(channel) => `#${channel.name}`}
                  getOptionValue={(channel) => channel.id}
                  placeholder="Select a channel"
                  emptyMessage="No matching channels found"
                  clearable={true}
                />
              </div>
              <p className="mt-2 text-sm text-gray-600">
                Channels appear when Braid is added to them
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );

  return (
    <LoadingOverlay
      isLoading={pageLoading && isUserProfileLoading}
      message="Loading content..."
    >
      <div className="container mx-auto space-y-6">
        <div className="bg-white rounded-lg shadow flex items-center w-full justify-between">
          <div className="px-6 py-5">
            <h2 className="text-2xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon
                icon={faPencilSquare}
                className="mr-3 text-brand"
              />
              Content Creation
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {isUserProfileLoading ? (
                <div className="h-5 w-96 bg-gray-200 animate-pulse rounded"></div>
              ) : isPremium ? (
                "Create and manage custom content for your games and activities"
              ) : (
                "Create custom content for your games with Braid Premium!"
              )}
            </p>
          </div>
          <div className="px-6 py-4">
            {isUserProfileLoading ? (
              <div className="h-10 w-32 bg-gray-200 animate-pulse rounded-lg"></div>
            ) : !isPremium ? (
              <button
                className="bg-gradient-cta text-white py-2 px-4 rounded-lg hover:bg-gradient-cta-hover transition-colors"
                onClick={() =>
                  navigate(teamId ? `/settings?team=${teamId}` : "/settings")
                }
              >
                Upgrade to Premium
              </button>
            ) : (
              <button
                className="bg-gradient-cta text-white py-2 px-4 rounded-lg hover:bg-gradient-cta-hover transition-colors"
                onClick={() => setShowCreator(true)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner color="white" height="4" />
                ) : (
                  "Create New Content"
                )}
              </button>
            )}
          </div>
        </div>

        {content.length === 0 && (
          <Card>
            <div className="text-gray-600">
              Once you've created your first content, it will show up below. You
              will only see content you've created.
            </div>
          </Card>
        )}

        {!showCreator && content.length > 0 ? (
          <Card>
            <div>
              <h3 className="text-lg font-semibold mb-6">Your Content</h3>
              {contentLoading ? (
                <div className="flex items-center justify-center py-8">
                  <Spinner color="purple" height="8" />
                </div>
              ) : (
                <ContentGrouped content={content} onRefresh={fetchContent} />
              )}
            </div>
          </Card>
        ) : null}

        {isPremium && showCreator && (
          <Card>
            <div className="space-y-8">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Create New Content</h3>
                <button
                  onClick={() => setShowCreator(false)}
                  className="px-2 py-1 rounded-lg border hover:border-brand hover:text-brand transition-colors duration-200 font-medium outline-red border-brandRed text-brandRed/90"
                >
                  Cancel
                </button>
              </div>
              {/* Setup Section */}
              <div>
                <div className="space-y-12">
                  {/* Step 1: Game Selection */}
                  <div>
                    <div className="flex items-center gap-2 mb-4">
                      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand/10 text-brand border-brand/20 border flex items-center justify-center font-semibold">
                        1
                      </div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Select Game Type
                      </h3>
                    </div>
                    <div className="pl-4 border-l-2 border-gray-100">
                      {renderGameSelector()}
                    </div>
                  </div>

                  {/* Step 2: Scope Selection */}
                  <div
                    className={
                      !selectedGame ? "opacity-50 pointer-events-none" : ""
                    }
                    aria-disabled={!selectedGame}
                  >
                    <div className="flex items-center gap-2 mb-4">
                      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand/10 text-brand border-brand/20 border flex items-center justify-center font-semibold">
                        2
                      </div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Select Content Audience
                      </h3>
                    </div>
                    {renderScopeSelector()}
                  </div>

                  {/* Step 3: Category Selection */}
                  <div
                    className={
                      !selectedGame ||
                      !scope ||
                      (scope === "channel" && !selectedChannel)
                        ? "opacity-50 pointer-events-none"
                        : ""
                    }
                    aria-disabled={
                      !selectedGame ||
                      !scope ||
                      (scope === "channel" && !selectedChannel)
                    }
                  >
                    <div className="flex items-center gap-2 mb-4">
                      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand/10 text-brand border border-brand/20 flex items-center justify-center font-semibold">
                        3
                      </div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Choose Category
                      </h3>
                    </div>
                    <div className="pl-4 border-l-2 border-gray-100">
                      {renderCategorySelector()}
                    </div>
                  </div>
                </div>
              </div>

              {/* Game Content Form */}
              {selectedGame && (scope !== "channel" || selectedChannel) && (
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-brand/10 text-brand flex items-center justify-center font-semibold">
                      4
                    </div>
                    <h3 className="text-lg font-medium text-gray-900">
                      Add Game Content
                    </h3>
                  </div>
                  <div className="pl-4 border-l-2 border-gray-100">
                    <div className="space-y-6">
                      {selectedGame === TRIVIA && (
                        <TriviaCreateForm
                          contentRows={contentRows}
                          setContentRows={setContentRows}
                          handleSubmit={handleSubmit}
                          isLoading={isLoading}
                          scope={scope}
                          selectedChannel={selectedChannel}
                          selectedCategory={selectedCategory}
                          channels={channels}
                          categories={categories}
                          selectedGame={selectedGame}
                        />
                      )}
                      {selectedGame === PICTURE_PUZZLE && (
                        <PicturePuzzleCreateForm
                          contentRows={contentRows}
                          setContentRows={setContentRows}
                          handleSubmit={handleSubmit}
                          isLoading={isLoading}
                          scope={scope}
                          selectedChannel={selectedChannel}
                          selectedCategory={selectedCategory}
                          channels={channels}
                          categories={categories}
                          selectedGame={selectedGame}
                          teamId={teamId}
                        />
                      )}
                      {selectedGame === THIS_OR_THAT && (
                        <ThisOrThatCreateForm
                          contentRows={contentRows}
                          setContentRows={setContentRows}
                          handleSubmit={handleSubmit}
                          isLoading={isLoading}
                          scope={scope}
                          selectedChannel={selectedChannel}
                          selectedCategory={selectedCategory}
                          channels={channels}
                          categories={categories}
                          selectedGame={selectedGame}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Card>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default GameContentCreator;
