import React, { useState } from "react";
import Card from "../card";
import Modal from "../modal";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useApi } from "../../hooks/useApi";
import { buildApiUrl } from "../../api";
import {
  canManageTeamMembers,
  canEditUser,
  UserRole,
} from "../../lib/permissions";

// Types
interface TeamMember {
  id: string;
  email: string;
  name: string;
  role: "admin" | "editor" | "member";
  is_disabled: boolean;
  avatar_url: string;
  is_owner?: boolean;
}

interface MembersTabProps {
  members: TeamMember[];
  isLoading: boolean;
  teamId: string | null;
  onMemberUpdate?: () => void; // Optional callback to refresh members list
  handleRoleChange?: (
    userId: string,
    newRole: "admin" | "editor" | "member",
  ) => Promise<void>;
  handleToggleUserStatus?: (
    userId: string,
    newStatus: "active" | "deactivated",
  ) => Promise<void>;
  currentUserRole?: UserRole; // The current user's role for permission checks
  currentUserId?: string | null; // The current user's ID for highlighting in the list
}

/**
 * Renders the Members tab of the Team page
 * Displays a list of team members with their roles and statuses
 */

/**
 * MemberManagementModal component
 * A modal for managing team member roles and status.
 * Only admins can perform these actions.
 */
interface MemberManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
  member: TeamMember | null;
  isCurrentUserAdmin: () => boolean;
  onRoleChange: (
    memberId: string,
    newRole: "admin" | "editor" | "member",
  ) => Promise<void>;
  onStatusChange: (
    memberId: string,
    newStatus: "active" | "deactivated",
  ) => Promise<void>;
  isUpdating: boolean;
  currentUserId?: string | null; // Current user ID to check if member is current user
  currentUserIsDisabled: boolean; // Whether the current user is disabled
}

const MemberManagementModal: React.FC<MemberManagementModalProps> = ({
  isOpen,
  onClose,
  member,
  isCurrentUserAdmin,
  onRoleChange,
  onStatusChange,
  isUpdating,
  currentUserId,
  currentUserIsDisabled,
}) => {
  // Track which action is being confirmed
  const [confirmAction, setConfirmAction] = useState<{
    type: "role" | "status";
    value: "admin" | "editor" | "member" | boolean;
  } | null>(null);

  if (!member) return null;

  // Check if this member is the current user or owner
  const isCurrentUser = currentUserId === member.id;
  // Check if this member is an owner (cannot be downgraded or disabled)
  const isOwner = member.is_owner || false;

  // Determine if user can be disabled (owners can't be disabled)
  const canToggleStatus = !isOwner;

  // Determine if current user is an admin (required for these actions)
  const isAdmin = isCurrentUserAdmin();

  // Handle role change request
  const handleRoleChangeRequest = (newRole: "admin" | "editor" | "member") => {
    // Verify the current user's permissions haven't changed and they're not disabled
    if (!isAdmin || currentUserIsDisabled) {
      toast.error("You don't have permission to change member roles", {
        position: "bottom-center",
      });
      return;
    }

    // Owners cannot be downgraded
    if (isOwner) {
      toast.error("Team owners cannot be downgraded", {
        position: "bottom-center",
      });
      return;
    }

    setConfirmAction({ type: "role", value: newRole });
  };

  // Handle status change request
  const handleStatusChangeRequest = (isDisabled: boolean) => {
    // Debug log for troubleshooting
    console.log(
      `handleStatusChangeRequest called with isDisabled=${isDisabled}`,
    );

    // Verify the current user's permissions haven't changed and they're not disabled
    if (!isAdmin || currentUserIsDisabled) {
      toast.error("You don't have permission to change member status", {
        position: "bottom-center",
      });
      return;
    }

    // Owners cannot be disabled
    if (isOwner) {
      toast.error("Team owners cannot be disabled", {
        position: "bottom-center",
      });
      return;
    }

    console.log(`Setting confirmAction with value=${isDisabled}`);
    setConfirmAction({
      type: "status",
      value: isDisabled,
    });
  };

  // Handle confirmation of an action
  const handleConfirm = async () => {
    if (!confirmAction || !member) return;

    // Verify permissions again before executing the action, checking both admin status and account status
    if (!isAdmin || currentUserIsDisabled) {
      toast.error("You don't have permission to make changes", {
        position: "bottom-center",
      });
      setConfirmAction(null);
      return;
    }

    try {
      if (confirmAction.type === "role") {
        await onRoleChange(
          member.id,
          confirmAction.value as "admin" | "editor" | "member",
        );
      } else if (confirmAction.type === "status") {
        // Debug log to verify the value before calling onStatusChange
        console.log(
          `About to call onStatusChange with value=${confirmAction.value}`,
        );
        console.log(`Current member status: is_disabled=${member.is_disabled}`);

        // Convert boolean to required string format (true → "deactivated", false → "active")
        const newStatus = (confirmAction.value as boolean)
          ? "deactivated"
          : "active";
        await onStatusChange(member.id, newStatus);
      }

      // Reset and close
      setConfirmAction(null);
      onClose();
    } catch (error) {
      console.error("Error performing action:", error);
      // Keep modal open on error
    }
  };

  // Handle cancel of an action
  const handleCancel = () => {
    setConfirmAction(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => !isUpdating && onClose()}
      size="medium"
    >
      <div className="p-6">
        {/* Show confirmation UI if an action is selected */}
        {confirmAction ? (
          <div className="space-y-4">
            <h2 className="text-lg font-semibold mb-4">
              {confirmAction.type === "role"
                ? `Change ${member.name}'s role to ${confirmAction.value}?`
                : confirmAction.value === false
                  ? `Enable ${member.name}?`
                  : `Disable ${member.name}?`}
            </h2>

            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-600">
                {confirmAction.type === "role"
                  ? `This will change the user's permissions on the team.`
                  : confirmAction.value === false
                    ? `This will restore the user's access to team features and content creation.`
                    : `This user will no longer be able to access team features or create content.`}
              </p>
              {confirmAction.type === "status" &&
                confirmAction.value === true && (
                  <p className="mt-2 text-sm font-medium text-gray-700">
                    Note: You can re-enable them later if needed.
                  </p>
                )}
            </div>

            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                disabled={isUpdating}
                className="px-4 py-2 text-brand hover:text-brandRed font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                disabled={isUpdating}
                className={`px-4 py-2 bg-brand text-white rounded-lg hover:bg-brandRed font-medium flex items-center`}
              >
                {isUpdating && (
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Confirm
              </button>
            </div>
          </div>
        ) : (
          /* Show management options if no action is selected */
          <div className="space-y-6">
            <h2 className="text-lg font-semibold mb-4">
              Manage Team Member: {member.name}
            </h2>

            {/* Current information */}
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-600">Current Role</p>
                  <p className="font-medium capitalize text-gray-900">
                    {member.role}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Current Status</p>
                  <p className="font-medium capitalize text-gray-900">
                    {member.is_disabled ? "Disabled" : "Active"}
                  </p>
                </div>
              </div>
            </div>

            {isAdmin ? (
              <>
                {/* Role management section */}
                <div className="mb-6">
                  <h3 className="text-md font-semibold mb-3">Change Role</h3>
                  <p className="text-sm text-gray-500 mb-3">
                    Click a role to change this member's permissions
                  </p>
                  <div className="grid grid-cols-3 gap-3">
                    <button
                      onClick={() => handleRoleChangeRequest("admin")}
                      disabled={member.role === "admin" || isUpdating}
                      className={`p-2.5 rounded-lg text-center transition-all ${member.role === "admin" ? "bg-brand bg-opacity-20 border-2 border-brand text-brand shadow-sm" : "border-2 border-gray-200 hover:border-brand hover:text-brand hover:shadow-sm hover:bg-brand/5"} disabled:opacity-50 transform hover:scale-105 duration-150`}
                    >
                      Admin
                    </button>
                    <button
                      onClick={() => handleRoleChangeRequest("editor")}
                      disabled={
                        member.role === "editor" ||
                        isUpdating ||
                        (isCurrentUser && member.role === "admin")
                      }
                      className={`p-2.5 rounded-lg text-center transition-all ${member.role === "editor" ? "bg-brand bg-opacity-20 border-2 border-brand text-brand shadow-sm" : "border-2 border-gray-200 hover:border-brand hover:text-brand hover:shadow-sm hover:bg-brand/5"} disabled:opacity-50 transform hover:scale-105 duration-150`}
                      title={
                        isCurrentUser && member.role === "admin"
                          ? "Owners cannot downgrade themselves"
                          : ""
                      }
                    >
                      Editor
                    </button>
                    <button
                      onClick={() => handleRoleChangeRequest("member")}
                      disabled={
                        member.role === "member" ||
                        isUpdating ||
                        (isCurrentUser && member.role === "admin")
                      }
                      className={`p-2.5 rounded-lg text-center transition-all ${member.role === "member" ? "bg-brand bg-opacity-20 border-2 border-brand text-brand shadow-sm" : "border-2 border-gray-200 hover:border-brand hover:text-brand hover:shadow-sm hover:bg-brand/5"} disabled:opacity-50 transform hover:scale-105 duration-150`}
                      title={
                        isCurrentUser && member.role === "admin"
                          ? "Owners cannot downgrade themselves"
                          : ""
                      }
                    >
                      Member
                    </button>
                  </div>
                </div>

                {/* Status management section */}
                {canToggleStatus && (
                  <div>
                    <h3 className="text-md font-semibold mb-3">
                      Change Status
                    </h3>
                    <div className="">
                      {/* Only show Enable User if the member is disabled */}
                      {member.is_disabled && (
                        <button
                          onClick={() => handleStatusChangeRequest(false)}
                          disabled={isUpdating}
                          className="p-2.5 rounded-lg text-center transition-colors border-2 border-gray-200 hover:border-green-500 hover:text-green-600 hover:bg-green-50 disabled:opacity-50 flex justify-center items-center space-x-1 w-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          <span>Enable User</span>
                        </button>
                      )}
                      {!member.is_disabled && (
                        <button
                          onClick={() => handleStatusChangeRequest(true)}
                          disabled={isUpdating}
                          className="p-2.5 rounded-lg transition-colors border-2 border-gray-200 hover:border-red-500 hover:text-red-600 hover:bg-red-50 disabled:opacity-50 flex justify-center items-center space-x-1 w-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                            />
                          </svg>
                          <span>Disable User</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="p-4 text-center text-gray-500 border border-gray-200 rounded-md">
                Admin privileges required to manage team members
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

/**
 * MembersTab component that displays team members and their roles
 */
const MembersTab: React.FC<MembersTabProps> = ({
  members,
  isLoading,
  teamId,
  onMemberUpdate,
  currentUserRole = "member", // Default to member (lowest permissions)
  currentUserId = null, // Default to null (no user highlighted)
}) => {
  const api = useApi();
  const [updatingMemberId, setUpdatingMemberId] = useState<string | null>(null);

  // Management modal state
  const [isManagementModalOpen, setIsManagementModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

  // Get the current user from the members list if not passed directly
  const currentUser = members.find((member) => member.id === currentUserId);
  // Check if the current user is disabled
  const isCurrentUserDisabled = currentUser?.is_disabled || false;
  // Check if the current user is an owner
  const isCurrentUserOwner = currentUser?.is_owner || false;

  // Permission helper functions - ensure we check both role AND disabled status
  const isCurrentUserAdmin = () =>
    canManageTeamMembers(
      currentUserRole,
      isCurrentUserDisabled,
      isCurrentUserOwner,
    );

  // Only active admins can modify roles and statuses
  const userCanModify = () => {
    // Double-check that the user is not disabled first, then check if they're an admin
    // This way disabled users can never modify anything, regardless of their role
    if (isCurrentUserDisabled) {
      return false;
    }
    return isCurrentUserAdmin();
  };

  /**
   * Opens the management modal for a team member
   */
  const handleManageMember = (member: TeamMember) => {
    // Check if the current user can edit this member
    const canEdit = canEditUser(
      currentUserRole,
      isCurrentUserDisabled,
      member.role,
      member.is_owner,
      member.id === currentUserId,
      isCurrentUserOwner, // Pass the current user's owner status
    );

    if (!canEdit) {
      toast.error("You don't have permission to manage this team member", {
        position: "bottom-center",
      });
      return;
    }

    setSelectedMember(member);
    setIsManagementModalOpen(true);
  };

  /**
   * Handle role change
   * This function is passed to the MemberManagementModal to handle role updates
   */
  const handleRoleChange = async (
    memberId: string,
    newRole: "admin" | "editor" | "member",
  ) => {
    // Check if the current user has permission to make changes
    if (!userCanModify()) {
      toast.error("You don't have permission to change member roles", {
        position: "bottom-center",
      });
      return;
    }

    setUpdatingMemberId(memberId);
    try {
      // Use parent handler if provided, otherwise use local implementation
      // Call the API to update the member's role (fallback)
      // The API expects a role payload with the new role string
      await api.post(buildApiUrl(`/v2/slack/users/${memberId}/role`, teamId), {
        role: newRole,
      });

      toast.success(`Role updated successfully`);

      if (onMemberUpdate) {
        onMemberUpdate();
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to update role", { position: "bottom-center" });
      // Re-throw to handle in the modal
      throw error;
    } finally {
      setUpdatingMemberId(null);
      // Close the modal on success (this will only execute if no error was thrown)
      setIsManagementModalOpen(false);
    }
  };

  /**
   * Handle toggle user status
   * This function is passed to the MemberManagementModal to handle status updates
   *
   * @param memberId - The ID of the user to update
   * @param newStatus - The new status for the user ("active" or "deactivated")
   */
  const handleToggleStatus = async (
    memberId: string,
    newStatus: "active" | "deactivated",
  ) => {
    // Convert the string status to boolean isDisabled value
    const isDisabled = newStatus === "deactivated";
    // Check if the current user has permission to make changes
    if (!userCanModify()) {
      toast.error("You don't have permission to change member status", {
        position: "bottom-center",
      });
      return;
    }

    setUpdatingMemberId(memberId);
    try {
      // Make a direct API call to update the user's status
      // When isDisabled=true, we're disabling the user (sending disabled: true)
      // When isDisabled=false, we're enabling the user (sending disabled: false)
      await api.post(
        buildApiUrl(`/v2/slack/users/${memberId}/status`, teamId),
        {
          disabled: isDisabled, // This directly corresponds to the UI action
        },
      );

      // Show success message based on the action taken
      toast.success(`User ${isDisabled ? "disabled" : "enabled"} successfully`);

      // Update the local state to reflect the change immediately
      if (selectedMember) {
        setSelectedMember({
          ...selectedMember,
          is_disabled: isDisabled,
        });
      }

      // If there's a parent callback for updates, call it
      if (onMemberUpdate) {
        onMemberUpdate();
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to update user status", {
        position: "bottom-center",
      });
      // Re-throw to handle in the modal
      throw error;
    } finally {
      setUpdatingMemberId(null);
      // Close the modal on success (this will only execute if no error was thrown)
      setIsManagementModalOpen(false);
    }
  };

  return (
    <div className="space-y-8">
      {/* Member Management Modal */}
      {selectedMember && (
        <MemberManagementModal
          isOpen={isManagementModalOpen}
          onClose={() => setIsManagementModalOpen(false)}
          member={selectedMember}
          isCurrentUserAdmin={isCurrentUserAdmin}
          onRoleChange={handleRoleChange}
          onStatusChange={handleToggleStatus}
          isUpdating={updatingMemberId === selectedMember.id}
          currentUserId={currentUserId}
          currentUserIsDisabled={isCurrentUserDisabled}
        />
      )}

      <Card>
        <div className="p-6">
          <h3 className="text-lg font-medium mb-4">Team Members</h3>
          {isLoading ? (
            <div className="py-4 text-center">
              <div className="animate-spin w-6 h-6 border-2 border-brand border-t-transparent rounded-full mx-auto mb-2"></div>
              <p className="text-gray-500">Loading team members...</p>
            </div>
          ) : members.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center overflow-hidden">
                            {member.avatar_url ? (
                              <img
                                src={member.avatar_url}
                                alt={
                                  member.name || member.email || "Team member"
                                }
                                className="h-10 w-10 rounded-full object-cover"
                                onError={(e) => {
                                  // Handle image load errors
                                  e.currentTarget.style.display = "none";
                                  // Check if parentElement exists before accessing it
                                  if (e.currentTarget.parentElement) {
                                    e.currentTarget.parentElement.classList.add(
                                      "bg-brand/10",
                                    );
                                  }
                                }}
                              />
                            ) : (
                              // Create initials from name, fall back to email, then to a generic icon
                              <span className="text-brand font-medium text-sm">
                                {member.name
                                  ? member.name
                                      .split(" ")
                                      .map((n) => n[0])
                                      .join("")
                                      .toUpperCase()
                                      .substring(0, 2)
                                  : member.email
                                    ? member.email.charAt(0).toUpperCase()
                                    : "👤"}
                              </span>
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900 flex items-center">
                              {/* Display name with fallback to username */}
                              {member.name ||
                                "@" + member.email?.split("@")[0] ||
                                "Anonymous User"}
                              {currentUserId === member.id && (
                                <span className="inline-flex items-center justify-center h-5 ml-2 bg-brand/20 text-brand text-xs font-medium px-2 py-0.5 rounded-full">
                                  You
                                </span>
                              )}
                            </div>
                            <div className="text-gray-500 text-sm">
                              {member.email || "No email provided"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <span
                          className={`px-3 py-1.5 inline-flex items-center space-x-1 text-xs leading-5 font-medium rounded-md bg-gray-50 border-gray-200 border
                                                    ${userCanModify() && !member.is_owner ? "cursor-pointer hover:shadow-sm transform hover:scale-105 transition-all duration-150" : "opacity-70"}`}
                          onClick={() => {
                            if (!userCanModify()) {
                              // Only show error for disabled users, not for other permission issues
                              if (isCurrentUserDisabled) {
                                toast.error(
                                  "Disabled users cannot manage team members",
                                  { position: "bottom-center" },
                                );
                              }
                              return;
                            }
                            handleManageMember(member);
                          }}
                          title={
                            userCanModify()
                              ? "Click to change role"
                              : "You don't have permission to manage members"
                          }
                        >
                          {member.is_owner
                            ? "Owner"
                            : member.role.charAt(0).toUpperCase() +
                              member.role.slice(1)}
                          {userCanModify() && !member.is_owner && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3 ml-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                              />
                            </svg>
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <span
                          className={`px-2 py-2 inline-flex text-xs font-medium rounded-md items-center
                          ${
                            !member.is_disabled
                              ? "bg-green-50 text-green-700 border border-green-200"
                              : "bg-red-50 text-red-700 border border-red-200"
                          }
                          ${userCanModify() && !member.is_owner ? "cursor-pointer hover:shadow-sm transform hover:scale-105 transition-all duration-150" : "opacity-70"}`}
                          onClick={() => {
                            // First check if user is allowed to modify members
                            if (!userCanModify()) {
                              // Only show error for disabled users, not for other permission issues
                              if (isCurrentUserDisabled) {
                                toast.error(
                                  "Disabled users cannot manage team members",
                                  { position: "bottom-center" },
                                );
                              }
                              return;
                            }

                            // All members can be modified now that we're using is_disabled
                            handleManageMember(member);
                          }}
                          title={
                            userCanModify()
                              ? "Click to manage status"
                              : "You don't have permission to manage this member"
                          }
                        >
                          {member.is_disabled ? "Disabled" : "Active"}
                          {userCanModify() && !member.is_owner && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3 ml-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                              />
                            </svg>
                          )}
                          {updatingMemberId === member.id && (
                            <svg
                              className="animate-spin ml-1 h-3 w-3"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="py-4 text-center text-gray-500">
              No team members found.
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default MembersTab;
