import { ContentRow, GameName } from "../../types/games";
import {
  CUSTOMIZATION_GAME_CONFIGS as GAME_CONFIGS,
  THIS_OR_THAT,
} from "../../constants";
import { validateContentRow } from "../../lib/forms";

interface ThisOrThatCreateFormProps {
  contentRows: ContentRow[];
  setContentRows: React.Dispatch<React.SetStateAction<ContentRow[]>>;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  isLoading: boolean;
  scope: string;
  selectedChannel: string;
  selectedCategory: string;
  channels: Array<{ id: string; name: string }>;
  categories: Array<{ id: string; name: string; human_name?: string }>;
  selectedGame: GameName | null;
}

export const ThisOrThatCreateForm = ({
  contentRows,
  setContentRows,
  handleSubmit,
  isLoading,
  scope,
  selectedChannel,
  selectedCategory,
  channels,
  categories,
  selectedGame,
}: ThisOrThatCreateFormProps) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="pt-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            This or That Questions
          </h3>
          <button
            type="button"
            onClick={() => setContentRows([...contentRows, { id: Date.now() }])}
            className="px-3 py-1 text-sm bg-brand bg-opacity-10 text-brand rounded-md hover:bg-opacity-20 transition-colors duration-150 flex items-center gap-1"
          >
            + Add Question
          </button>
        </div>

        {contentRows.map((row, index) => (
          <div
            key={row.id}
            className="space-y-4 mb-6 pb-6 border-b border-brand/20 last:border-b-0"
          >
            <div className="flex justify-between items-start">
              <h4 className="text-sm font-medium text-gray-700">
                Question {index + 1}
              </h4>
              {contentRows.length > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    const newRows = contentRows.filter((_, i) => i !== index);
                    setContentRows(newRows);
                  }}
                  className="px-3 py-1 text-sm bg-red-50 text-red-600 rounded-md hover:bg-red-100 hover:text-red-700 transition-colors duration-150 flex items-center gap-1"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  Remove
                </button>
              )}
            </div>

            {GAME_CONFIGS[THIS_OR_THAT].fields.map((field, idx) => (
              <div key={field}>
                <label className="block text-sm font-medium text-gray-700">
                  {field
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </label>
                <input
                  type="text"
                  placeholder={GAME_CONFIGS[THIS_OR_THAT].placeholders[idx]}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
                  value={row[field] || ""}
                  onChange={(e) => {
                    const newRows = [...contentRows];
                    newRows[index] = { ...row, [field]: e.target.value };
                    setContentRows(newRows);
                  }}
                  required
                />
              </div>
            ))}
          </div>
        ))}

        <div className="flex items-center gap-4 mt-4 justify-end">
          <span className="text-sm text-gray-600">
            Adds to{" "}
            {scope === "channel"
              ? `#${channels.find((c) => c.id === selectedChannel)?.name}`
              : "workspace"}
            {selectedCategory
              ? ` • ${categories.find((c) => c.id === selectedCategory)?.human_name || categories.find((c) => c.id === selectedCategory)?.name}`
              : " • Select a category"}
          </span>

          <button
            type="submit"
            disabled={
              isLoading ||
              (scope === "channel" && !selectedChannel) ||
              !selectedCategory ||
              contentRows.length === 0 ||
              contentRows.some((row) =>
                selectedGame
                  ? !validateContentRow(row, selectedGame).isValid
                  : true,
              )
            }
            className="px-4 py-2 bg-brand text-white rounded-md hover:bg-brandCompliment disabled:opacity-50 min-w-[80px] text-center"
          >
            {isLoading ? "Adding..." : "Add"}
          </button>
        </div>
      </div>
    </form>
  );
};
