import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserShield,
  faUsers,
  faCrown,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../card";

// Types
// No longer need permission toggles as permissions are now fixed per role

interface AccessControlTabProps {
  teamId: string | null;
}

// Role descriptions for the different user roles
const roleDescriptions = {
  owner: "Has complete control over the team, including managing admins",
  admin: "Can manage team settings, billing, and access controls",
  editor: "Can create content and create games",
  member: "Can only create games",
  disabled: "Cannot perform any actions until their account is re-enabled",
};

/**
 * Renders the Access Control tab of the Team page
 * Displays information about role permissions for team members
 */
const AccessControlTab: React.FC<AccessControlTabProps> = () => {
  return (
    <div className="space-y-6">
      <Card>
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-lg font-medium">Team Access Permissions</h3>
              <p className="text-gray-600 mt-1">
                Overview of the permissions for different roles in your team
              </p>
            </div>
          </div>

          <div className="space-y-6 mt-6">
            {/* Owner Permissions */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon
                  icon={faCrown}
                  className="text-amber-500 mr-2"
                />
                <h4 className="text-md font-medium">Owner</h4>
              </div>
              <p className="text-sm text-gray-600 mb-3">
                {roleDescriptions.owner}
              </p>
              <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-500">
                Owners have the highest level of access. They can manage all
                team members including admins, and have full control over all
                aspects of the team. Each team has one owner who cannot be
                downgraded or disabled.
              </div>
            </div>

            {/* Admin Permissions */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon
                  icon={faUserShield}
                  className="text-brand mr-2"
                />
                <h4 className="text-md font-medium">Admin</h4>
              </div>
              <p className="text-sm text-gray-600 mb-3">
                {roleDescriptions.admin}
              </p>
              <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-500">
                Admins have full access to team settings, can manage members and
                editors, and control billing information. Admins cannot modify
                other admins unless they are the team owner.
              </div>
            </div>

            {/* Editor Permissions */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon icon={faUsers} className="text-brand mr-2" />
                <h4 className="text-md font-medium">Editor</h4>
              </div>
              <p className="text-sm text-gray-600 mb-3">
                {roleDescriptions.editor}
              </p>
              <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-500">
                Editors can create and manage content and games. This role is
                for team members who need content creation capabilities but
                don't require administrative permissions.
              </div>
            </div>

            {/* Member Permissions */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon icon={faUsers} className="text-brand mr-2" />
                <h4 className="text-md font-medium">
                  Member{" "}
                  <span className="text-xs font-normal text-gray-500 ml-2">
                    (Default)
                  </span>
                </h4>
              </div>
              <p className="text-sm text-gray-600 mb-3">
                {roleDescriptions.member}
              </p>
              <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-500">
                Members can create games but cannot create or manage content.
                This is the default role assigned to new team members when they
                join. Admins can later upgrade members to other roles as needed.
              </div>
            </div>

            {/* Disabled Status */}
            <div className="border border-gray-200 rounded-lg p-4">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon icon={faBan} className="text-red-500 mr-2" />
                <h4 className="text-md font-medium">Disabled Users</h4>
              </div>
              <p className="text-sm text-gray-600 mb-3">
                {roleDescriptions.disabled}
              </p>
              <div className="bg-gray-50 p-3 rounded-md text-sm text-gray-500">
                When a user is disabled, they cannot log in or perform any
                actions on the platform regardless of their role. Only admins
                and the team owner can enable disabled users.
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AccessControlTab;
