import { useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
import Spinner from "./components/spinner";

interface PublicOnlyRouteProps {
  children: React.ReactNode;
}

/**
 * A route wrapper that only allows unauthenticated users to access
 * Redirects authenticated users to home page
 */
function PublicOnlyRoute({ children }: PublicOnlyRouteProps) {
  const { isLoaded, isSignedIn } = useUser();

  // Show a loading spinner while authentication status is being determined
  if (!isLoaded) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner height="16" />
      </div>
    );
  }

  // If user is signed in, redirect to home page
  if (isSignedIn) {
    return <Navigate to="/" replace />;
  }

  // If user is not signed in, render the children
  return <>{children}</>;
}

export default PublicOnlyRoute;
