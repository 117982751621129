import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./sidebar";
import { useUserData } from "../hooks/useUserData";
import { useUser, UserButton } from "@clerk/clerk-react";
import Logo from "../assets/braid-logo.png";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  // State for sidebar and mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Use localStorage to persist sidebar state across page refreshes
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(() => {
    try {
      // Try to get saved preference from localStorage
      const saved = localStorage.getItem("sidebar-collapsed");
      return saved === "true";
    } catch (error) {
      // If localStorage is not available or fails, default to expanded
      console.warn("Failed to access localStorage:", error);
      return false;
    }
  });

  // Get route and user data
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const { data: userData } = useUserData();
  const { user } = useUser();

  // Save sidebar state to localStorage whenever it changes
  useEffect(() => {
    try {
      localStorage.setItem("sidebar-collapsed", String(isSidebarCollapsed));
    } catch (error) {
      console.warn("Failed to save to localStorage:", error);
    }
  }, [isSidebarCollapsed]);

  // Close mobile menu when screen size changes to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileMenuOpen]);

  // Toggle handlers
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarCollapsed((prev) => !prev);
  }, []);

  // Removed debug logging

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-50">
      {/* Header - fixed at the top */}
      <header className="bg-white shadow z-20 flex-shrink-0">
        <div className="w-full px-4 md:px-6 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Sidebar toggle for desktop */}
            <button
              className="hidden md:flex text-brand hover:text-brandCompliment p-1 rounded-md hover:bg-gray-100"
              onClick={toggleSidebar}
              aria-label="Toggle sidebar"
              data-testid="sidebar-toggle"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>

            {/* Menu toggle for mobile */}
            <button
              className="md:hidden text-brand hover:text-brandCompliment"
              onClick={toggleMobileMenu}
              aria-label="Toggle menu"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </button>

            <img
              src={Logo}
              alt="Braid Teams Logo"
              className="h-8 w-auto ml-1"
            />
          </div>

          {/* User info and menu on right side */}
          <div className="flex items-center text-sm">
            {user && (
              <div className="flex items-center pr-2">
                <span className="mr-3 font-medium">
                  {user.fullName || user.username}
                </span>
                {/* Clerk UserButton with sign out functionality */}
                <UserButton
                  afterSignOutUrl="/"
                  appearance={{
                    elements: {
                      userButtonAvatarBox: "h-8 w-8",
                      userButtonTrigger: "focus:shadow-none",
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Content container - takes remaining height */}
      <div className="flex flex-1 overflow-hidden relative">
        {/* Sidebar - fixed positioning relative to this container */}
        <Sidebar
          installations={userData?.installations || []}
          currentTeamId={teamId}
          isMobileOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          isCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />

        {/* Main content - only this should scroll */}
        <main
          className={`
            flex-1 overflow-auto py-6 px-4 md:px-6 lg:px-8
            transition-[margin] duration-300 ease-out
            ${
              typeof window !== "undefined" && window.innerWidth >= 768
                ? isSidebarCollapsed
                  ? "md:ml-16"
                  : "md:ml-64"
                : ""
            }
          `}
        >
          <div className="max-w-5xl mx-auto transition-all duration-300">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
