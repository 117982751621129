import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartSimple,
  faCalendar,
  faPencilSquare,
  faBuilding,
  faCheck,
  faUsers,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import { SlackInstallation } from "../types/user";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../hooks/useUserProfile";
import { isAtLeastEditor } from "../lib/permissions";

// Helper to create links that preserve the team parameter
const createTeamLink = (path: string, teamId: string | null) => {
  return teamId ? `${path}?team=${teamId}` : path;
};

// Navigation items definition - permissions will be checked at runtime
const navItems = [
  { to: "/profile", icon: faChartSimple, text: "Insights", editorOnly: false },
  {
    to: "/create",
    icon: faPencilSquare,
    text: "Create",
    editorOnly: true,
    tooltip: "Editor or Admin role required",
  },
  {
    to: "/events",
    icon: faCalendar,
    text: "Events",
    editorOnly: true,
    tooltip: "Editor or Admin role required",
  },
  { to: "/team", icon: faUsers, text: "Team", editorOnly: false },
  { to: "/settings", icon: faCog, text: "Settings", editorOnly: false },
];

const isActivePath = (path: string, pathname: string): boolean => {
  return pathname.startsWith(path);
};

interface WorkspaceSwitcherProps {
  installations: SlackInstallation[];
  currentTeamId: string | null;
  isCollapsed: boolean;
  toggleSidebar?: () => void;
}

const WorkspaceSwitcher = ({
  installations,
  currentTeamId,
  isCollapsed,
  toggleSidebar,
}: WorkspaceSwitcherProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const currentWorkspace =
    installations.find(
      (installation) => installation.team_id === currentTeamId,
    ) || installations[0];

  // Effect removed

  const handleWorkspaceChange = (teamId: string) => {
    if (teamId === currentTeamId) {
      setIsOpen(false);
      return;
    }

    // Preserve the current path when switching teams
    const currentPath = window.location.pathname;
    navigate(`${currentPath}?team=${teamId}`, { replace: true });
    setIsOpen(false);
  };

  // Only render if we have a current workspace
  if (!currentWorkspace) {
    return null;
  }

  // Handle changing teams or expanding sidebar if collapsed
  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // If sidebar is collapsed, expand it first
    if (isCollapsed && toggleSidebar) {
      toggleSidebar();
      // Wait for sidebar animation to complete
      setTimeout(() => setIsOpen(true), 300);
      return;
    }

    // Toggle dropdown
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    // Add a click handler to close the dropdown when clicking outside
    if (newIsOpen) {
      const handleOutsideClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        // Close dropdown if clicking outside
        if (!target.closest("[data-switcher]")) {
          setIsOpen(false);
          document.removeEventListener("click", handleOutsideClick);
        }
      };

      // Add with a small delay to avoid the current click event
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
      }, 10);
    }
  };

  return (
    <div className="relative px-2 overflow-visible z-50" data-switcher>
      <button
        type="button"
        onClick={handleButtonClick}
        disabled={isCollapsed}
        aria-haspopup="true"
        aria-expanded={isOpen}
        className={`
          flex items-center justify-between px-3 py-2 text-brand rounded-lg
          ${isOpen ? "bg-brand/20" : "bg-brand/10"} 
          hover:bg-brand/30 transition-colors text-sm font-medium
          w-full overflow-hidden
          ${isCollapsed ? "pointer-events-none" : "cursor-pointer"}
        `}
      >
        <span className="truncate mr-2 flex-1">
          {currentWorkspace?.team_name}
        </span>
        <FontAwesomeIcon
          icon={faChevronUp}
          className={`text-gray-400 transition-transform duration-200 flex-shrink-0 h-3 w-3
            ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {/* Team dropdown opening upward */}
      {isOpen && !isCollapsed && (
        <div
          data-switcher-dropdown
          className="absolute py-2 w-60 bottom-full left-0 mb-1 shadow-lg
          bg-white rounded-md border border-gray-200 z-50 max-h-72 overflow-y-auto animate-fadeIn"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header for the workspace switcher dropdown */}
          <div className="px-3 py-2 mb-1 border-b border-gray-100 bg-gray-50">
            <h3 className="text-xs font-medium text-gray-600 uppercase">
              Select Team
            </h3>
          </div>

          {installations.map((installation) => (
            <button
              key={installation.team_id}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling
                handleWorkspaceChange(installation.team_id);
              }}
              className={`w-full px-3 py-2 text-left text-sm flex items-center
                hover:bg-brandLight transition-colors
                ${
                  currentTeamId === installation.team_id
                    ? "text-brand font-medium bg-brand/5"
                    : "text-gray-700"
                }`}
            >
              <div className="flex items-center w-full">
                {currentTeamId === installation.team_id && (
                  <span className="mr-2 text-brand">
                    <FontAwesomeIcon icon={faCheck} className="w-3 h-3" />
                  </span>
                )}
                <span className="truncate block font-medium">
                  {installation.team_name}
                </span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

interface SidebarProps {
  installations?: SlackInstallation[];
  currentTeamId: string | null;
  isMobileOpen: boolean;
  toggleMobileMenu: () => void;
  isCollapsed: boolean;
  toggleSidebar?: () => void; // Add toggle sidebar function
}

function Sidebar({
  installations = [],
  currentTeamId,
  isMobileOpen,
  toggleMobileMenu,
  isCollapsed,
  toggleSidebar,
}: SidebarProps) {
  const { pathname } = useLocation();
  const { userRole, isAccountOwner, userData } = useUserProfile();

  // Check if the user is at least an editor or is disabled
  const canAccessEditorFeatures = isAtLeastEditor(
    userRole,
    userData?.slack_user?.is_disabled,
    isAccountOwner,
  );

  // Using Tailwind's classes directly instead of joining arrays
  // Track sidebar transitions to control scrollbar visibility
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Manage transition state when sidebar collapses/expands
  useEffect(() => {
    // Enable transitioning state to hide scrollbars temporarily
    setIsTransitioning(true);
    const timer = setTimeout(() => setIsTransitioning(false), 350); // slightly longer than transition
    return () => clearTimeout(timer);
  }, [isCollapsed]);

  const sidebarClasses = `
    bg-white shadow-lg border-r border-gray-200 flex flex-col
    transition-all duration-400 ease-out
    ${isCollapsed ? "w-16" : "w-64"}
    fixed top-14 left-0 bottom-0 z-50 pt-0 overflow-hidden
    ${isMobileOpen ? "translate-x-0" : "-translate-x-full"}
    md:fixed md:top-14 md:bottom-0 md:left-0 md:z-10 md:translate-x-0 md:pt-0
  `
    .trim()
    .replace(/\s+/g, " ");

  return (
    <>
      {/* Mobile overlay - only show on mobile when sidebar is open */}
      {isMobileOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={toggleMobileMenu}
        />
      )}

      {/* Sidebar component */}
      <aside className={sidebarClasses}>
        <div
          className={`flex flex-col h-full ${isTransitioning ? "overflow-hidden" : "overflow-x-hidden overflow-y-auto"}`}
        >
          {/* Navigation menu */}
          <nav
            className={`flex-1 pt-6 pb-0 font-dmSans scrollbar-hide touch-pan-y
              ${isTransitioning ? "overflow-hidden" : "overflow-y-auto overflow-x-hidden"}`}
          >
            <div className="space-y-2 px-2 pb-4">
              {navItems.map(({ to, icon, text, editorOnly, tooltip }) => {
                const isActive = isActivePath(to, pathname);

                // Determine if this item should be disabled based on permissions
                const isDisabled = editorOnly && !canAccessEditorFeatures;

                // Use template literal directly for cleaner conditional classes
                const linkClasses =
                  `py-3 rounded-md text-sm flex items-center transition-colors relative
                  ${isCollapsed ? "justify-center px-3" : "px-4"}
                  ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}
                  ${isActive ? "bg-brand/10 text-brandCompliment font-medium" : "text-brand hover:bg-brand/5"}
                `
                    .trim()
                    .replace(/\s+/g, " ");

                // Simplified tooltip approach using title attribute when appropriate
                const tooltipText = isDisabled
                  ? tooltip
                  : isCollapsed
                    ? text
                    : undefined;

                return (
                  <div key={to} className="relative group" title={tooltipText}>
                    {isDisabled ? (
                      /* Render a non-clickable div for disabled items */
                      <div className={linkClasses}>
                        <FontAwesomeIcon
                          icon={faLock}
                          className="absolute top-3 right-2 text-gray-400 text-xs"
                          fixedWidth
                        />
                        <FontAwesomeIcon
                          icon={icon}
                          className={isCollapsed ? "w-5 h-5" : "w-4 h-4"}
                          fixedWidth
                        />
                        {!isCollapsed && <span className="ml-3">{text}</span>}
                      </div>
                    ) : (
                      /* Render a proper Link for enabled items */
                      <Link
                        to={createTeamLink(to, currentTeamId)}
                        className={linkClasses}
                        onClick={isMobileOpen ? toggleMobileMenu : undefined}
                      >
                        <FontAwesomeIcon
                          icon={icon}
                          className={isCollapsed ? "w-5 h-5" : "w-4 h-4"}
                          fixedWidth
                        />
                        {!isCollapsed && <span className="ml-3">{text}</span>}
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </nav>

          {/* Workspace switcher at bottom */}
          {installations.length > 0 && (
            <div className="px-2 pb-3 flex-shrink-0 relative mt-auto overflow-visible min-h-[60px] z-50">
              {/* Stacked container for smooth cross-fade transition */}
              <div className="relative">
                {/* Collapsed state - icon */}
                <div
                  className={`absolute inset-0 w-full flex justify-center items-center
                  transition-all duration-500 ease-out
                  ${isCollapsed ? "opacity-100 z-10" : "opacity-0 z-0"}`}
                >
                  <button
                    onClick={toggleSidebar}
                    className="py-3 px-4 rounded-md text-brand hover:text-brandCompliment hover:bg-brand/5 transition-colors"
                    title="Expand sidebar to switch teams"
                    aria-label="Expand sidebar to switch teams"
                  >
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="w-5 h-5"
                      fixedWidth
                    />
                  </button>
                </div>

                {/* Expanded state - full workspace switcher */}
                <div
                  className={`inset-0 w-full transition-all duration-300 ease-out
                  ${isCollapsed ? "opacity-0 z-0 pointer-events-none" : "opacity-100 z-10"}`}
                >
                  <h3 className="text-xs font-medium text-gray-400 uppercase tracking-wider mb-2 px-2">
                    Teams
                  </h3>
                  <WorkspaceSwitcher
                    installations={installations}
                    currentTeamId={currentTeamId}
                    isCollapsed={isCollapsed}
                    toggleSidebar={toggleSidebar}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
