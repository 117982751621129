import { useUserData } from "./useUserData";
import { Subscription, SubscriptionTier } from "../types/user";
import { UserRole } from "../lib/permissions";

export function useUserProfile() {
  const {
    data: userData,
    isLoading,
    isError,
    error,
    refetch: refetchUserData,
  } = useUserData();

  const subscription: Subscription | undefined =
    userData?.slack_user?.subscription;

  const subscriptionTier: SubscriptionTier | undefined = subscription?.tier;

  const isPremium =
    userData?.slack_user?.subscription?.tier?.tier_type === "Premium";

  // Get user role, defaulting to 'member' if not found
  const userRole: UserRole = userData?.slack_user?.role || "member";

  // Check if user is the account owner
  const isAccountOwner = userData?.slack_user?.is_owner === true;

  return {
    isLoading,
    isError,
    isPremium,
    error,
    userData,
    subscription,
    subscriptionTier,
    userRole,
    isAccountOwner,
    refetchUserData,
  };
}
