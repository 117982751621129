// pages/events.tsx
import Card from "../components/card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import { SlackUser } from "../types/user";

import { useUserProfile } from "../hooks/useUserProfile";

import Spinner from "../components/spinner";
import EventCard from "../components/events/eventCard";

import { generateSlackDeepLink } from "../lib/utils";

import { Event } from "../types/event";

interface CurrentEventsProps {
  slackUser: SlackUser;
}

interface ChannelEventGroupProps {
  channelName: string;
  events: Event[];
}

const ChannelEventGroup = ({ channelName, events }: ChannelEventGroupProps) => {
  return (
    <div className="mb-6 border-2 border-gray-100 rounded-xl px-5 pt-5 pb-5 bg-white">
      <h4 className="font-semibold text-lg mb-4 text-gray-700">
        #{channelName}
      </h4>
      <div className="space-y-4">
        {events.map((event, idx) => (
          <EventCard key={idx} event={event} />
        ))}
      </div>
    </div>
  );
};

function CurrentEvents({ slackUser }: CurrentEventsProps) {
  const { events } = slackUser;

  const hasEvents = events && events.length > 0;

  // Group events by channel
  const eventsByChannel = events?.reduce(
    (acc, event) => {
      const channel = event.channel_name;
      if (!acc[channel]) {
        acc[channel] = [];
      }
      acc[channel].push(event);
      return acc;
    },
    {} as Record<string, Event[]>,
  );

  return (
    <Card>
      <div>
        {hasEvents ? (
          <>
            <h3 className="text-lg font-semibold mb-6">Your events</h3>
            <div className="text-gray-600 mb-6">
              All events you've created are shown here. You can not edit an
              event someone else created.
            </div>
            <div className="space-y-6">
              {Object.entries(eventsByChannel).map(
                ([channel, channelEvents]) => (
                  <ChannelEventGroup
                    key={channel}
                    channelName={channel}
                    events={channelEvents}
                  />
                ),
              )}
            </div>
          </>
        ) : (
          <div className="text-gray-600">
            Once you've created your first recurring event, it will show up
            below. You will only view events you've created.
          </div>
        )}
      </div>
    </Card>
  );
}

function Events() {
  const { userData, isLoading, isError, error, isPremium } = useUserProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-brandRed">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  if (!userData || !userData.slack_user) {
    return <div className="text-center mb-4">No user data available.</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="space-y-6">
        {/* Page header with title and action button */}
        <div className="bg-white rounded-lg shadow flex items-center w-full justify-between">
          <div className="px-6 py-5">
            <h2 className="text-2xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon icon={faCalendar} className="mr-3 text-brand" />
              Events
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              {isPremium
                ? "Manage existing events and schedule new recurring events"
                : "Schedule games and activities in advance with Braid Premium!"}
            </p>
          </div>
          <div className="px-6 py-4">
            {isPremium ? (
              <a
                href={generateSlackDeepLink(userData.slack_user)}
                className="bg-gradient-cta text-white py-3 px-4 rounded-lg hover:bg-gradient-cta-hover transition-colors"
              >
                Setup in Slack
              </a>
            ) : (
              <button
                className="bg-gradient-cta text-white py-2 px-4 rounded-lg hover:bg-gradient-cta-hover transition-colors"
                onClick={() =>
                  navigate(
                    teamId
                      ? `/settings?team=${teamId}&tab=billing`
                      : "/settings",
                  )
                }
              >
                Upgrade to Premium
              </button>
            )}
          </div>
        </div>

        <CurrentEvents slackUser={userData.slack_user} />
      </div>
    </div>
  );
}

export default Events;
