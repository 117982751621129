/**
 * Permissions utility functions
 *
 * Provides a set of helper functions to check user permissions
 * based on roles within the application.
 */

// Define the types of roles available in the system
export type UserRole = "admin" | "editor" | "member";

/**
 * Check if a user has admin permissions
 *
 * @param role - The role of the user
 * @param is_disabled - Whether the user is disabled
 * @param is_owner - Whether the user is the team owner
 * @returns Boolean indicating whether user has admin permissions
 */
export const isAdmin = (
  role?: UserRole,
  is_disabled?: boolean,
  is_owner?: boolean,
): boolean => {
  // Owner flag trumps everything - owners are always considered admins regardless of disabled status
  if (is_owner) {
    return true;
  }
  // Otherwise, must be an admin role and not disabled
  return role === "admin" && !is_disabled;
};

/**
 * Check if a user has at least editor permissions
 * Editor permissions are given to both editors and admins
 *
 * @param role - The role of the user
 * @param is_disabled - Whether the user is disabled
 * @param is_owner - Whether the user is the team owner
 * @returns Boolean indicating whether user has at least editor permissions
 */
export const isAtLeastEditor = (
  role?: UserRole,
  is_disabled?: boolean,
  is_owner?: boolean,
): boolean => {
  // Owner flag trumps everything
  if (is_owner) {
    return true;
  }
  // Otherwise, must be admin or editor and not disabled
  return (role === "admin" || role === "editor") && !is_disabled;
};

/**
 * Determine if a user can access billing/subscription features
 * Only admins should have access to billing
 *
 * @param role - The role of the user
 * @param is_disabled - Whether the user is disabled
 * @param is_owner - Whether the user is the team owner
 * @returns Boolean indicating whether user can access billing
 */
export const canAccessBilling = (
  role?: UserRole,
  is_disabled?: boolean,
  is_owner?: boolean,
): boolean => {
  return isAdmin(role, is_disabled, is_owner);
};

/**
 * Determine if a user can manage other team members
 * Only admins can manage team members
 *
 * @param role - The role of the user
 * @param is_disabled - Whether the user is disabled
 * @param is_owner - Whether the user is the team owner
 * @returns Boolean indicating whether user can manage team members
 */
export const canManageTeamMembers = (
  role?: UserRole,
  is_disabled?: boolean,
  is_owner?: boolean,
): boolean => {
  return isAdmin(role, is_disabled, is_owner);
};

/**
 * Check if current user has permission to edit a particular user
 * Admins can edit members and editors, but cannot edit other admins
 * Team owners can edit all users (including admins)
 * Disabled users cannot edit anyone regardless of role
 * Owners (is_owner flag) cannot be downgraded or disabled
 *
 * @param currentUserRole - The role of the current user
 * @param currentUserIsDisabled - Whether the current user is disabled
 * @param targetUserRole - The role of the user being edited
 * @param targetUserIsOwner - Whether the target user is the team owner
 * @param isCurrentUser - Whether the target user is the current user
 * @param currentUserIsOwner - Whether the current user is the team owner
 * @returns Boolean indicating whether current user can edit target user
 */
export const canEditUser = (
  currentUserRole?: UserRole,
  currentUserIsDisabled?: boolean,
  targetUserRole?: UserRole,
  targetUserIsOwner: boolean = false,
  isCurrentUser: boolean = false,
  currentUserIsOwner: boolean = false,
): boolean => {
  // Users can't edit themselves
  if (isCurrentUser) {
    return false;
  }

  // Owners cannot be edited (downgraded or disabled)
  if (targetUserIsOwner) {
    return false;
  }

  // Disabled users cannot edit other users
  if (currentUserIsDisabled) {
    return false;
  }

  // Team owner can edit anyone
  if (currentUserIsOwner) {
    return true;
  }

  // Only admins can edit other users
  if (currentUserRole !== "admin") {
    return false;
  }

  // Admins can't edit other admins
  if (targetUserRole === "admin") {
    return false;
  }

  return true;
};
