import { useState, useRef, useEffect } from "react";

type AutocompleteProps<T> = {
  items: T[];
  value: string;
  onChange: (value: string) => void;
  getOptionLabel: (item: T) => string;
  getOptionValue: (item: T) => string;
  placeholder?: string;
  className?: string;
  emptyMessage?: string;
  clearable?: boolean;
  onCreateNew?: () => void;
  createNewLabel?: string;
};

/**
 * A reusable Autocomplete component built with Tailwind CSS
 */
const Autocomplete = <T extends object>({
  items,
  value,
  onChange,
  getOptionLabel,
  getOptionValue,
  placeholder = "Select an option",
  className = "",
  emptyMessage = "No options available",
  clearable = true,
  onCreateNew,
  createNewLabel = "Create new",
}: AutocompleteProps<T>) => {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Initialize display value when component mounts or value changes
  useEffect(() => {
    if (value) {
      const selectedItem = items.find((item) => getOptionValue(item) === value);
      if (selectedItem) {
        setInputValue(getOptionLabel(selectedItem));
      }
    } else {
      setInputValue("");
    }
  }, [value, items, getOptionLabel, getOptionValue]);

  // Filter items based on input
  useEffect(() => {
    if (inputValue.trim() === "") {
      setFilteredItems(items);
    } else {
      const filtered = items.filter((item) =>
        getOptionLabel(item).toLowerCase().includes(inputValue.toLowerCase()),
      );
      setFilteredItems(filtered);
    }
  }, [inputValue, items, getOptionLabel]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const handleSelectItem = (item: T) => {
    onChange(getOptionValue(item));
    setInputValue(getOptionLabel(item));
    setIsFocused(false);
    inputRef.current?.blur();
  };

  const handleClear = () => {
    onChange("");
    setInputValue("");
    inputRef.current?.focus();
  };

  return (
    <div className={`relative ${className}`} ref={containerRef}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          placeholder={placeholder}
          className="block w-full px-4 py-2 text-gray-700 bg-white border-b border-gray-400 rounded appearance-none hover:border-b-2 hover:border-brand focus:outline-none focus:border-b-2 focus:border-brand transition-all duration-200"
        />
        {clearable && inputValue && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-3 top-1/2 -translate-y-1/2 p-1 text-gray-400 hover:text-gray-600"
            title="Clear selection"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>

      {isFocused && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredItems.length > 0 ? (
            <ul className="py-1">
              {filteredItems.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectItem(item)}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >
                  {getOptionLabel(item)}
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-3 text-sm text-gray-500">{emptyMessage}</div>
          )}

          {onCreateNew && (
            <div className="border-t border-gray-100">
              <button
                onClick={() => {
                  onCreateNew();
                  setIsFocused(false);
                }}
                className="w-full text-left px-4 py-2 text-brand hover:bg-gray-100 font-medium flex items-center"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                {createNewLabel}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
