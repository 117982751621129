import { useApi } from "../../hooks/useApi";
import { toast } from "react-toastify";
import { useState } from "react";
import { format } from "date-fns";
import Spinner from "../spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Modal from "../modal";
import {
  CUSTOMIZATION_GAME_CONFIGS,
  THIS_OR_THAT,
  PICTURE_PUZZLE,
  TRIVIA,
} from "../../constants";

interface Content {
  id: string;
  content_type: string;
  category?: {
    id: string;
    name: string;
    human_name: string;
  };
  channel?: {
    id: string;
    name: string;
  };
  data: {
    choice_1?: string;
    choice_2?: string;
    question?: string;
    answer?: string;
    options?: string;
    clue?: string;
    given?: string;
    image_url?: string;
  };
  created: string;
  modified: string;
  is_one_off: boolean;
}

const getGameConfig = (contentType: string) => {
  switch (contentType) {
    case "this-or-that":
      return CUSTOMIZATION_GAME_CONFIGS[THIS_OR_THAT];
    case "trivia":
      return CUSTOMIZATION_GAME_CONFIGS[TRIVIA];
    case "picture-puzzle":
      return CUSTOMIZATION_GAME_CONFIGS[PICTURE_PUZZLE];
    default:
      return null;
  }
};

const formatContentType = (contentType: string) => {
  return contentType
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

interface ContentCardProps {
  content: Content | Content[];
  onRefresh?: () => void;
  isLoading?: boolean;
}

interface GroupedCategory {
  id: string;
  name: string;
  human_name: string;
  items: Content[];
}

interface GroupedGameType {
  type: string;
  categories: GroupedCategory[];
  uncategorized: Content[];
}

const groupContent = (contents: Content | Content[]): GroupedGameType[] => {
  const items = Array.isArray(contents) ? contents : [contents];

  return Object.values(
    items.reduce(
      (acc, item) => {
        const type = item.content_type;

        if (!acc[type]) {
          acc[type] = {
            type,
            categories: [],
            uncategorized: [],
          };
        }

        if (item.category) {
          const existingCategory = acc[type].categories.find(
            (c) => item.category && c.id === item.category.id,
          );

          if (existingCategory) {
            existingCategory.items.push(item);
          } else {
            acc[type].categories.push({
              id: item.category.id,
              name: item.category.name,
              human_name: item.category.human_name,
              items: [item],
            });
          }
        } else {
          acc[type].uncategorized.push(item);
        }

        return acc;
      },
      {} as Record<string, GroupedGameType>,
    ),
  );
};

interface DeleteContentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: Content;
  isDeleting: boolean;
}

const DeleteContentModal = ({
  isOpen,
  onClose,
  onConfirm,
  content,
  isDeleting,
}: DeleteContentModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-4">
          Are you sure you want to delete this content?
        </h2>

        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center gap-x-2 mb-3">
            <span className="font-medium text-gray-900">
              {content.content_type
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </span>
            <span className="text-gray-400">&middot;</span>
            <SourceTag content={content} />
          </div>

          <div className="border-t border-gray-200 pt-3">
            <ContentPreview
              contentType={content.content_type}
              data={content.data}
            />
          </div>

          {content.category && (
            <div className="mt-3 pt-3 border-t border-gray-200">
              <p className="text-sm text-gray-600">
                Category: {content.category.human_name || content.category.name}
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-brand hover:text-brand font-medium"
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className={`px-4 py-2 rounded-lg font-medium inline-flex items-center ${isDeleting ? "bg-red-400 cursor-not-allowed" : "bg-brand hover:bg-brandRed"} text-white`}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Deleting...
              </>
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ContentPreview = ({
  contentType,
  data,
}: {
  contentType: string;
  data: Content["data"];
}) => {
  switch (contentType) {
    case "this-or-that":
      return (
        <div className="text-lg text-gray-600">
          {data.choice_1}
          <span className="mx-2 text-sm">or</span>
          {data.choice_2}
        </div>
      );
    case "trivia":
      return (
        <div className="text-sm text-gray-600">
          <div>
            <span className="font-medium">Q:</span> {data.question}
          </div>
          <div className="text-xs text-gray-400">
            <span className="font-medium">A:</span> {data.answer}
            {data.options && <span className="ml-2">({data.options})</span>}
          </div>
        </div>
      );
    case "picture-puzzle":
      return (
        <div className="text-sm text-gray-600 flex gap-3 items-start">
          {data.image_url && (
            <div className="flex-shrink-0">
              <img
                src={data.image_url}
                alt="Picture Puzzle"
                className="w-20 h-20 object-cover rounded-md"
              />
            </div>
          )}
          <div>
            <div>
              <span className="font-medium">Clue:</span> {data.clue}
            </div>
            <div className="text-xs text-gray-400">
              <span className="font-medium">Answer:</span> {data.answer}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const GameTypeSection = ({
  groupedContent,
  onRefresh,
}: {
  groupedContent: GroupedGameType;
  onRefresh?: () => void;
}) => {
  const { type, categories, uncategorized } = groupedContent;
  const gameConfig = getGameConfig(type);
  const api = useApi();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedContent, setSelectedContent] = useState<Content | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);

  const handleDelete = async (content: Content) => {
    try {
      setIsDeleting(true);
      const searchParams = new URLSearchParams(window.location.search);
      const team = searchParams.get("team");

      if (!team) {
        throw new Error("Team parameter is required");
      }

      await api.delete(`/v2/games/library/content/${content.id}?team=${team}`);

      // Since this is a soft delete (sets is_active=false), we should remove it from the current view
      const updatedItems = allItems.filter((item) => item.id !== content.id);
      setAllItems(updatedItems);

      toast.success("Content removed successfully");
    } catch (error: any) {
      console.error("Failed to delete content:", error);
      toast.error(error?.response?.data?.detail || "Failed to remove content");
    } finally {
      setIsDeleting(false);
      setSelectedContent(null);
      onRefresh?.();
    }
  };

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortItems = (items: Content[]) => {
    if (!sortConfig) return items;

    return [...items].sort((a, b) => {
      if (sortConfig.key === "created") {
        const dateA = new Date(a.created).getTime();
        const dateB = new Date(b.created).getTime();
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (sortConfig.key === "category") {
        const catA = a.category?.human_name || "";
        const catB = b.category?.human_name || "";
        return sortConfig.direction === "asc"
          ? catA.localeCompare(catB)
          : catB.localeCompare(catA);
      }

      if (sortConfig.key === "content") {
        let contentA = "";
        let contentB = "";

        // Extract sortable content based on content type
        if (a.content_type === "this-or-that") {
          contentA = [a.data.choice_1, a.data.choice_2]
            .filter(Boolean)
            .join(" ");
          contentB = [b.data.choice_1, b.data.choice_2]
            .filter(Boolean)
            .join(" ");
        } else if (a.content_type === "trivia") {
          contentA = a.data.question || "";
          contentB = b.data.question || "";
        } else if (a.content_type === "picture-puzzle") {
          contentA = [a.data.clue, a.data.answer].filter(Boolean).join(" ");
          contentB = [b.data.clue, b.data.answer].filter(Boolean).join(" ");
        }

        return sortConfig.direction === "asc"
          ? contentA.localeCompare(contentB)
          : contentB.localeCompare(contentA);
      }

      if (sortConfig.key === "scope") {
        const scopeA = a.channel?.name || "workspace";
        const scopeB = b.channel?.name || "workspace";
        return sortConfig.direction === "asc"
          ? scopeA.localeCompare(scopeB)
          : scopeB.localeCompare(scopeA);
      }

      return 0;
    });
  };

  const renderSortIcon = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) return "⬍";
    return sortConfig.direction === "asc" ? "↑" : "↓";
  };

  const renderTable = (items: Content[]) => {
    const sortedItems = sortItems(items);

    return (
      <div className="overflow-x-auto">
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th
                className="p-2 text-left cursor-pointer"
                onClick={() => handleSort("content")}
              >
                Content {renderSortIcon("content")}
              </th>
              <th
                className="p-2 text-left cursor-pointer"
                onClick={() => handleSort("category")}
              >
                Category {renderSortIcon("category")}
              </th>
              <th
                className="p-2 text-left cursor-pointer"
                onClick={() => handleSort("created")}
              >
                Created {renderSortIcon("created")}
              </th>
              <th
                className="p-2 text-left cursor-pointer"
                onClick={() => handleSort("scope")}
              >
                Audience {renderSortIcon("scope")}
              </th>
              <th className="p-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedItems.map((item) => (
              <tr key={item.id} className="border-b hover:bg-gray-50">
                <td className="p-2">
                  <ContentPreview contentType={type} data={item.data} />
                </td>
                <td className="p-2">
                  <span className="text-xs p-2 rounded-lg text-gray-900 bg-brand/10">
                    {item.category?.human_name || "Uncategorized"}
                  </span>
                </td>
                <td className="p-2 text-sm">
                  {format(new Date(item.created), "MMM d, yyyy")}
                </td>
                <td className="p-2">
                  <SourceTag content={item} />
                </td>
                <td className="p-2 text-center">
                  <button
                    onClick={() => setSelectedContent(item)}
                    className={`transition-colors ${isDeleting && selectedContent?.id === item.id ? "text-red-600 cursor-not-allowed" : "text-gray-600 hover:text-red-600"}`}
                    title="Delete content"
                    disabled={isDeleting}
                  >
                    {isDeleting && selectedContent?.id === item.id ? (
                      <svg
                        className="animate-spin h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <FontAwesomeIcon icon={faTrash} />
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (!gameConfig) return null;

  const [allItems, setAllItems] = useState([
    ...categories.flatMap((cat) => cat.items),
    ...uncategorized,
  ]);
  const totalItems = allItems.length;

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold flex items-center gap-x-2">
          <span className="text-brand">{gameConfig.icon}</span>
          {formatContentType(type)}
        </h2>
        <div className="text-sm text-gray-500">
          {totalItems} item{totalItems !== 1 ? "s" : ""}
        </div>
      </div>
      {renderTable(allItems)}
      {selectedContent && (
        <DeleteContentModal
          isOpen={!!selectedContent}
          onClose={() => setSelectedContent(null)}
          onConfirm={() => {
            if (selectedContent) {
              handleDelete(selectedContent);
              setSelectedContent(null);
            }
          }}
          content={selectedContent}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

const ContentGrouped = ({
  content,
  onRefresh,
  isLoading,
}: ContentCardProps) => {
  const contentArray = Array.isArray(content) ? content : [content];
  const groupedContent = groupContent(contentArray);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Spinner color="purple" height="8" />
      </div>
    );
  }

  return (
    <div className="space-y-12">
      {groupedContent.map((gameTypeContent) => {
        return (
          <GameTypeSection
            key={gameTypeContent.type}
            groupedContent={gameTypeContent}
            onRefresh={onRefresh}
          />
        );
      })}
    </div>
  );
};

const SourceTag = ({
  content,
  className = "",
}: {
  content: Content;
  className?: string;
}) => {
  if (content.channel) {
    return (
      <span
        className={`inline-flex items-center p-2 rounded-lg text-xs font-medium bg-gray-100 text-gray-800 ${className}`}
      >
        <span className="text-gray-500 mr-1">#</span>
        {content.channel.name}
      </span>
    );
  }
  return (
    <span
      className={`inline-flex items-center p-2 rounded-lg text-xs font-medium bg-gray-100 text-gray-800 ${className}`}
    >
      <span className="mr-1">🏢</span>
      Workspace
    </span>
  );
};

export default ContentGrouped;
