const API_BASE_URL = import.meta.env.VITE_BASE_API_URL;

const MINIMUM_PLAYS_FOR_INSIGHTS = 3;

const THIS_OR_THAT = "SLACK_THIS_OR_THAT";
const WORD_LINK = "SLACK_WORD_LINK";
const PICTURE_PUZZLE = "SLACK_PICTURE_PUZZLE";
const TYPING_TEST = "SLACK_TYPING_TEST";
const WORDSMITH = "SLACK_WORDSMITH";
const TRIVIA = "SLACK_TRIVIA";

const DEFAULT_SEAT_COUNT_UPGRADE = 20; // When upgrading set the default seat count to this number

// Subscription Constants
const SEAT_PRICE_MONTH_ANNUAL = 5; // When paid annually
const SEAT_PRICE_MONTH = 7; // When paid monthly
const MAX_SEATS = 250; // Maximum number of seats allowed for self-service updates

const CUSTOMIZATION_GAME_CONFIGS = {
  [THIS_OR_THAT]: {
    title: "This or That",
    icon: "⁉️",
    fields: ["choice_1", "choice_2"],
    placeholders: ["🐈 Cats", "🐕 Dogs"],
    description: "Create custom This or That questions",
    bulkTemplate: "choice_1,choice_2\nLeft,Right\nCats,Dogs",
  },
  [TRIVIA]: {
    title: "Trivia",
    icon: "🧠",
    fields: ["question", "options", "answer"],
    description: "Create custom trivia questions",
    bulkTemplate:
      "question,answer,option1,option2,option3,option4\nWhat color is the sky?,Blue,Red,Blue,Green,Yellow",
  },
  [PICTURE_PUZZLE]: {
    title: "Picture Puzzle",
    icon: "🖼️",
    fields: ["answer", "clue"],
    placeholders: ["The Princess Bride", "Cult Classic Movies"],
    description: "Create custom picture puzzles",
    requiresImage: true,
  },
  //   [TYPING_TEST]: {
  //   title: "Typing Test",
  //   icon: "🖥️",
  //   fields: ["text"],
  //   description: "Create custom typing tests",
  //   bulkTemplate: "text\nThe quick brown fox jumps over the lazy dog",
  // },
};

export {
  API_BASE_URL,
  MINIMUM_PLAYS_FOR_INSIGHTS,
  THIS_OR_THAT,
  WORD_LINK,
  PICTURE_PUZZLE,
  TYPING_TEST,
  WORDSMITH,
  TRIVIA,
  SEAT_PRICE_MONTH_ANNUAL,
  SEAT_PRICE_MONTH,
  MAX_SEATS,
  DEFAULT_SEAT_COUNT_UPGRADE,
  CUSTOMIZATION_GAME_CONFIGS,
};
