import { ContentRow, GameName } from "../../types/games";
import { validateContentRow } from "../../lib/forms";

interface TriviaCreateFormProps {
  contentRows: ContentRow[];
  setContentRows: React.Dispatch<React.SetStateAction<ContentRow[]>>;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  isLoading: boolean;
  scope: string;
  selectedChannel: string;
  selectedCategory: string;
  channels: Array<{ id: string; name: string }>;
  categories: Array<{ id: string; name: string; human_name?: string }>;
  selectedGame: GameName | null;
}

export const TriviaCreateForm = ({
  contentRows,
  setContentRows,
  handleSubmit,
  isLoading,
  scope,
  selectedChannel,
  selectedCategory,
  channels,
  categories,
  selectedGame,
}: TriviaCreateFormProps) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="pt-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Questions</h3>
          <button
            type="button"
            onClick={() => setContentRows([...contentRows, { id: Date.now() }])}
            className="px-3 py-1 text-sm bg-brand bg-opacity-10 text-brand rounded-md hover:bg-opacity-20 transition-colors duration-150 flex items-center gap-1"
          >
            + Add Question
          </button>
        </div>

        {contentRows.map((row, index) => (
          <div
            key={row.id}
            className="space-y-4 mb-6 pb-6 border-b border-gray-200 last:border-b-0"
          >
            <div className="flex justify-between items-start">
              <h4 className="text-sm font-medium text-gray-700">
                Question {index + 1}
              </h4>
              {contentRows.length > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    const newRows = contentRows.filter((_, i) => i !== index);
                    setContentRows(newRows);
                  }}
                  className="px-3 py-1 text-sm bg-red-50 text-red-600 rounded-md hover:bg-red-100 hover:text-red-700 transition-colors duration-150 flex items-center gap-1"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  Remove
                </button>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Question
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
                placeholder="What is the capital of France?"
                value={row.question || ""}
                onChange={(e) => {
                  const newRows = [...contentRows];
                  newRows[index] = { ...row, question: e.target.value };
                  setContentRows(newRows);
                }}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Answer
              </label>
              <input
                type="text"
                placeholder="Paris"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
                value={row.answer || ""}
                onChange={(e) => {
                  const newAnswer = e.target.value;
                  const newRows = [...contentRows];
                  // Find first empty option slot, or option that matches old answer
                  const optionSlot =
                    [1, 2, 3, 4].find(
                      (n) =>
                        !row[`option${n}`] || row[`option${n}`] === row.answer,
                    ) || 1;
                  newRows[index] = {
                    ...row,
                    answer: newAnswer,
                    [`option${optionSlot}`]: newAnswer,
                    // Update options string
                    options: [1, 2, 3, 4]
                      .map((n) =>
                        n === optionSlot ? newAnswer : row[`option${n}`] || "",
                      )
                      .filter(Boolean)
                      .join(","),
                  };
                  setContentRows(newRows);
                }}
              />
              <p className="mt-1 text-sm text-gray-600">
                Must match one of the options below exactly
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Options
              </label>
              <div className="grid grid-cols-2 gap-4">
                {[1, 2, 3, 4].map((num) => (
                  <div key={num}>
                    <input
                      type="text"
                      placeholder={`Option ${num}`}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
                      value={row[`option${num}`] || ""}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const newRows = [...contentRows];
                        // If this option was the answer, clear the answer
                        const updatedRow = {
                          ...row,
                          [`option${num}`]: newValue,
                          // Clear answer if it matched the old option value
                          ...(row[`option${num}`] === row.answer
                            ? { answer: "" }
                            : {}),
                          // Reconstruct options string whenever an option changes
                          options: [1, 2, 3, 4]
                            .map((n) =>
                              n === num ? newValue : row[`option${n}`] || "",
                            )
                            .filter(Boolean)
                            .join(","),
                        };
                        newRows[index] = updatedRow;
                        setContentRows(newRows);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <div className="flex items-center gap-4 mt-4 justify-end">
          <span className="text-sm text-gray-600">
            Adds to{" "}
            {scope === "channel"
              ? `#${channels.find((c) => c.id === selectedChannel)?.name}`
              : "workspace"}
            {selectedCategory
              ? ` • ${categories.find((c) => c.id === selectedCategory)?.human_name || categories.find((c) => c.id === selectedCategory)?.name}`
              : " • Select a category"}
          </span>

          <button
            type="submit"
            disabled={
              isLoading ||
              (scope === "channel" && !selectedChannel) ||
              !selectedCategory ||
              contentRows.some((row) =>
                selectedGame
                  ? !validateContentRow(row, selectedGame).isValid
                  : true,
              )
            }
            className="px-4 py-2 bg-brand text-white rounded-md hover:bg-brandCompliment disabled:opacity-50 min-w-[80px] text-center"
          >
            {isLoading ? "Adding..." : "Add"}
          </button>
        </div>
      </div>
    </form>
  );
};
