import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./sidebar";
import Logo from "../assets/braid-logo.png";

/**
 * A version of the Layout component specifically for onboarding
 * Shows the full app UI similar to the authenticated version
 * but doesn't require actual authentication
 */
interface OnboardingLayoutProps {
  children: React.ReactNode;
  showModal?: boolean;
  onToggleModal?: (show: boolean) => void;
}

function OnboardingLayout({
  children,
  showModal = true,
  onToggleModal,
}: OnboardingLayoutProps) {
  // State for sidebar and mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  // Get route params
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  // Mock installations for sidebar display
  const mockInstallations = teamId
    ? [
        {
          team_id: teamId,
          team_name: "Your Workspace",
          team_icon: null,
          is_enterprise_install: false,
        },
      ]
    : [];

  // Toggle handlers
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarCollapsed((prev) => !prev);
  }, []);

  // Handle sign in button click - always shows the modal
  const handleSignInClick = useCallback(() => {
    if (onToggleModal) {
      onToggleModal(true);
    }
  }, [onToggleModal]);

  // Close mobile menu when screen size changes to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileMenuOpen]);

  return (
    <div
      className={`h-screen flex flex-col overflow-hidden bg-gray-50 ${showModal ? "filter blur-sm pointer-events-none" : ""}`}
    >
      {/* Header - fixed at the top */}
      <header className="bg-white shadow z-20 flex-shrink-0">
        <div className="w-full px-4 md:px-6 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Sidebar toggle for desktop */}
            <button
              className="hidden md:flex text-brand hover:text-brandCompliment p-1 rounded-md hover:bg-gray-100"
              onClick={toggleSidebar}
              aria-label="Toggle sidebar"
              data-testid="sidebar-toggle"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>

            {/* Menu toggle for mobile */}
            <button
              className="md:hidden text-brand hover:text-brandCompliment"
              onClick={toggleMobileMenu}
              aria-label="Toggle menu"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </button>

            <img
              src={Logo}
              alt="Braid Teams Logo"
              className="h-8 w-auto ml-1"
            />
          </div>

          {/* Sign in button on right side */}
          <div className="flex items-center text-sm">
            <button
              onClick={handleSignInClick}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium"
            >
              Sign in
            </button>
          </div>
        </div>
      </header>

      {/* Content container - takes remaining height */}
      <div className="flex flex-1 overflow-hidden relative">
        {/* Sidebar - fixed positioning relative to this container */}
        <Sidebar
          installations={mockInstallations}
          currentTeamId={teamId}
          isMobileOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          isCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />

        {/* Main content - only this should scroll */}
        <main
          className={`
            flex-1 overflow-auto py-6 px-4 md:px-6 lg:px-8
            transition-[margin] duration-300 ease-out
            ${
              typeof window !== "undefined" && window.innerWidth >= 768
                ? isSidebarCollapsed
                  ? "md:ml-16"
                  : "md:ml-64"
                : ""
            }
          `}
        >
          <div className="max-w-5xl mx-auto transition-all duration-300">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default OnboardingLayout;
