import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { motion } from "motion/react";
import confetti from "canvas-confetti";
import OnboardingLayout from "../components/onboarding-layout";
import SlackSignIn from "../components/auth/signin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDice,
  faCog,
  faCalendar,
  faPencil,
  faChartSimple,
  faGamepad,
  faClock,
  faBoltLightning,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";

import CreatureFireworks from "../assets/creature_fireworks.png";
import SoftBGGradient from "../assets/soft_bg_gradient.png";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  step: number;
  slackAppUrl: string;
  teamId: string;
}

// Premium features list for step 2
const premiumFeatures = [
  {
    icon: faDice,
    text: "Monthly game releases",
  },
  {
    icon: faCog,
    text: "Game themes and customization",
  },
  {
    icon: faPencil,
    text: "Bulk content management",
  },
  {
    icon: faChartSimple,
    text: "In-depth leaderboards",
  },
  {
    icon: faClock,
    text: "Schedule games and reminders",
  },
  {
    icon: faBoltLightning,
    text: "Slack shortcuts",
  },
  {
    icon: faShoppingBasket,
    text: "And other Premium features",
    subtext:
      "Coming soon: calendar integrations, scheduled journeys, Zoom app, and more!",
  },
];

// Modal component for each step with animations
function Modal({ children, isOpen, step, slackAppUrl, teamId }: ModalProps) {
  if (!isOpen) return null;

  // Different modal content based on step
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            key="step1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-xl z-10 w-[650px] max-w-[90vw] flex mx-4 overflow-hidden"
          >
            {/* Left column for text and button - now taking 2/3 of the space */}
            <div className="w-2/3 p-6 flex flex-col justify-between items-center min-h-[350px]">
              <div className="flex flex-col justify-center h-full">
                <h2 className="text-2xl font-bold">Success</h2>
                <p className="text-gray-700 mt-4 mb-0">
                  Braid is now installed in your Slack Workspace.
                </p>
                <p className="text-gray-700 mt-4 mb-0">
                  You’re minutes away from unlocking more team connection,
                  communication and productivity through play!
                </p>
              </div>
              {children}
            </div>

            {/* Right column for image - now taking 1/3 of the space with gradient background */}
            <div className="w-1/3 relative flex items-center justify-center p-4 overflow-hidden">
              <img
                src={SoftBGGradient}
                alt="Gradient Background"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <img
                src={CreatureFireworks}
                alt="Celebration"
                className="relative z-10 max-w-full max-h-full object-contain"
              />
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-xl z-10 w-[650px] max-w-[90vw] flex mx-4 overflow-hidden"
          >
            {/* Left column - Premium trial message */}
            <div className="w-2/3 p-6 flex flex-col justify-between items-center">
              <div className="flex flex-col justify-center h-full">
                <p className="text-lg font-semibold">You've unlocked a free</p>
                <p className="font-bold text-2xl">
                  14 day trial of Braid Premium
                </p>
              </div>
              {children}
            </div>

            {/* Right column - Premium features */}
            <div className="w-2/3 relative flex items-center justify-center p-4">
              <img
                src={SoftBGGradient}
                alt="Gradient Background"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="py-2 pr-2 flex flex-col relative z-10">
                <ul className="space-y-4 flex-1">
                  {/* Show only the first 5 features to prevent scrolling */}
                  {premiumFeatures.map((feature, index) => (
                    <li key={index} className="flex items-start gap-2">
                      <FontAwesomeIcon
                        icon={feature.icon}
                        className="w-4 h-4 mt-1 flex-shrink-0"
                      />
                      <div>
                        <p className="text-md font-medium text-gray-800">
                          {feature.text}
                        </p>
                        {feature.subtext && (
                          <p className="text-sm text-gray-600">
                            {feature.subtext}
                          </p>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            key="step3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative rounded-lg shadow-xl z-10 w-[650px] max-w-[90vw] mx-4 overflow-hidden p-6 py-8"
          >
            <img
              src={SoftBGGradient}
              alt="Gradient Background"
              className="absolute inset-0 w-full h-full object-cover"
            />

            <div className="flex items-center flex-col justify-center">
              <div className="text-center my-6 mb-8 relative z-10">
                <h2 className="text-2xl font-bold">
                  Start connecting with your first Braid game
                </h2>
              </div>

              <div className="grid grid-cols-3 gap-4 mb-6 relative z-10">
                {/* Play Now option - links to Slack */}
                <a
                  href={slackAppUrl}
                  className="bg-white/80 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                >
                  <div className="flex items-center justify-center h-12 w-12 bg-brand/10 rounded-full mb-3 mx-auto">
                    <FontAwesomeIcon
                      icon={faGamepad}
                      className="text-indigo-600"
                    />
                  </div>
                  <h3 className="text-center font-semibold mb-1">Play Now</h3>
                  <p className="text-sm text-gray-600 text-center">
                    Start a quick game with your team
                  </p>
                </a>

                {/* Schedule option - links to Slack */}
                <a
                  href={slackAppUrl}
                  className="bg-white/80 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                >
                  <div className="flex items-center justify-center h-12 w-12 bg-brand/10 rounded-full mb-3 mx-auto">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-indigo-600"
                    />
                  </div>
                  <h3 className="text-center font-semibold mb-1">Schedule</h3>
                  <p className="text-sm text-gray-600 text-center">
                    Plan a game for later
                  </p>
                </a>

                {/* Custom Game option - uses SlackSignIn with redirect */}
                <div
                  onClick={() => {
                    const signInElement =
                      document.getElementById("custom-game-signin");
                    signInElement?.click();
                  }}
                  className="bg-white/80 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                >
                  <div className="flex items-center justify-center h-12 w-12 bg-brand/10 rounded-full mb-3 mx-auto">
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-indigo-600"
                    />
                  </div>
                  <h3 className="text-center font-semibold mb-1">
                    Custom Game
                  </h3>
                  <p className="text-sm text-gray-600 text-center">
                    Create your own custom experience
                  </p>
                </div>

                {/* Hidden SlackSignIn for custom game */}
                <div className="hidden">
                  <SlackSignIn
                    id="custom-game-signin"
                    text="Create custom game"
                    redirectUrlComplete={`/create?team=${teamId}`}
                  />
                </div>
              </div>
              <div className="text-center text-sm text-gray-500 mt-5 z-10">
                <a
                  href={slackAppUrl}
                  className="text-xs hover:underline cursor-pointer"
                >
                  I'll do this another time
                </a>
              </div>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      {renderStepContent()}
    </div>
  );
}

/**
 * Post-installation onboarding page for Slack users
 * Shows the app UI with the actual app layout to give users a preview
 * of what they'll get, with modal onboarding steps guiding them
 */
/**
 * Creates a celebration effect with brand purple confetti
 */
const firePurpleConfetti = () => {
  // Brand purple color in hex
  const brandPurple = "#4A154B";
  const secondaryColor = "#ECE6F0"; // Light purple/lavender for contrast

  // Fire multiple confetti bursts for a more dramatic effect
  const fireConfetti = (originX: number, originY: number) => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: originX, y: originY },
      colors: [brandPurple, secondaryColor, "#A78BFA"],
      disableForReducedMotion: true, // Accessibility consideration
    });
  };

  // Center burst
  fireConfetti(0.5, 0.5);

  // Side bursts with slight delay
  setTimeout(() => {
    fireConfetti(0.2, 0.5);
    fireConfetti(0.8, 0.5);
  }, 200);
};

function OnboardingPage() {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const appId = searchParams.get("app");
  const [currentStep, setCurrentStep] = useState(1);
  const showModal = true;
  const setShowModal = () => {}; // Simplified since we always keep the modal visible

  // Track when step changes to trigger confetti on step 2
  useEffect(() => {
    // Only trigger confetti when user reaches step 2 (premium trial)
    if (currentStep === 2) {
      // Short delay to ensure the step transition has completed
      const timer = setTimeout(() => {
        firePurpleConfetti();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  // Create URLs with context for smoother onboarding flow
  // Ensure we have values for teamId and appId, default to empty string if null
  const safeTeamId = teamId || "";
  const safeAppId = appId || "";
  const slackAppUrl = `https://slack.com/app_redirect?team=${safeTeamId}&app=${safeAppId}`;

  return (
    <>
      {/* Use our actual app layout component */}
      <OnboardingLayout showModal={showModal} onToggleModal={setShowModal}>
        {/* Dashboard Content - Mimicking the profile page layout */}
        <div className="container mx-auto">
          <div className="space-y-4">
            {/* Comparison Bar (Mockup) */}
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex flex-wrap md:flex-nowrap gap-3 items-center">
                {/* Comparison Type Selector */}
                <div className="flex bg-gray-100 rounded-full p-1 space-x-1 shadow-sm">
                  {["Workspace", "Channel", "Direct"].map((type) => (
                    <div
                      key={type}
                      className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors cursor-pointer ${
                        type === "Workspace"
                          ? "bg-indigo-600 text-white"
                          : "text-gray-600 hover:bg-gray-200"
                      }`}
                    >
                      {type}
                    </div>
                  ))}
                </div>

                {/* Search Bar */}
                <div className="flex items-center gap-4 flex-1">
                  <div className="relative w-64 flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input
                      type="text"
                      className="block w-full pl-8 pr-3 py-1 border border-gray-300 bg-gray-200 rounded-[20px] focus:outline-none text-sm"
                      placeholder="Search channels..."
                      disabled
                    />
                  </div>

                  {/* Channel Pills - Showing popular channels */}
                  <div className="flex gap-2 overflow-x-auto">
                    {["general", "random", "team-games"].map((channel) => (
                      <span
                        key={channel}
                        className="flex-shrink-0 px-2 py-1 rounded-[20px] bg-indigo-100 text-indigo-800 text-sm"
                      >
                        # {channel}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Comparison Info Bar */}
            <div className="bg-white rounded-lg shadow-sm p-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <div className="bg-gray-100 p-2 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-indigo-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 14a6 6 0 100-12 6 6 0 000 12z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">
                      Workspace Overview
                    </h3>
                    <p className="text-sm text-gray-500">
                      Insights compared to all members
                    </p>
                  </div>
                </div>
                <div className="text-sm text-gray-500">Last 30 days</div>
              </div>
            </div>

            {/* Game Insights Cards */}
            <div className="space-y-4">
              {/* Word Association Insight Card */}
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="p-4 border-b">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <span className="text-2xl">🔤</span>
                      <h3 className="font-medium text-lg">Word Association</h3>
                    </div>
                    <span className="text-sm text-gray-500">
                      42 games played
                    </span>
                  </div>
                </div>

                <div className="p-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Stat Card 1 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Fastest Response
                      </h4>
                      <div className="text-2xl font-bold text-indigo-600">
                        1.8s
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        Top 15% in workspace
                      </div>
                    </div>

                    {/* Stat Card 2 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Accuracy Rate
                      </h4>
                      <div className="text-2xl font-bold text-indigo-600">
                        89%
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        Top 20% in workspace
                      </div>
                    </div>

                    {/* Stat Card 3 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Win Rate
                      </h4>
                      <div className="text-2xl font-bold text-indigo-600">
                        72%
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        Top 10% in workspace
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Trivia Challenge Insight Card */}
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="p-4 border-b">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <span className="text-2xl">🧠</span>
                      <h3 className="font-medium text-lg">Trivia Challenge</h3>
                    </div>
                    <span className="text-sm text-gray-500">
                      27 games played
                    </span>
                  </div>
                </div>

                <div className="p-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Stat Card 1 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Best Category
                      </h4>
                      <div className="text-xl font-bold text-indigo-600">
                        Science
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        92% correct answers
                      </div>
                    </div>

                    {/* Stat Card 2 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Average Score
                      </h4>
                      <div className="text-2xl font-bold text-indigo-600">
                        7.4/10
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        Top 25% in workspace
                      </div>
                    </div>

                    {/* Stat Card 3 */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h4 className="text-sm font-medium text-gray-500 mb-1">
                        Quickest Answer
                      </h4>
                      <div className="text-2xl font-bold text-indigo-600">
                        3.2s
                      </div>
                      <div className="text-sm text-gray-500 mt-2">
                        Faster than 80% of players
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OnboardingLayout>

      {/* Multi-step Modal */}
      <Modal
        isOpen={showModal}
        step={currentStep}
        slackAppUrl={slackAppUrl}
        teamId={safeTeamId}
      >
        {currentStep < 3 && (
          <div className="flex mt-4 gap-2">
            <div className="flex-1">
              <button
                className="w-full py-2 px-4 bg-brand text-white rounded-lg hover:bg-brand/80 transition"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                {currentStep === 2 ? "Start Trial" : "Next"}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default OnboardingPage;
