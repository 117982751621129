import { ClerkProvider } from "@clerk/clerk-react";
import { AuthenticateWithRedirectCallback } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TeamProvider } from "./contexts/TeamContext";

import Profile from "./pages/profile";
import Settings from "./pages/settings";
import LandingPage from "./pages/landing";
import Events from "./pages/events";
import GameContentCreator from "./pages/create";
import TeamPage from "./pages/team";
import OnboardingPage from "./pages/onboarding";

import ProtectedRoute from "./Protected";
import PublicOnlyRoute from "./PublicOnlyRoute";

import * as Sentry from "@sentry/react";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const queryClient = new QueryClient();

function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <>
            <LandingPage />
          </>
        }
      />
      <Route
        path="/onboarding"
        element={
          <PublicOnlyRoute>
            <OnboardingPage />
          </PublicOnlyRoute>
        }
      />
      <Route
        path="/events"
        element={
          <ProtectedRoute>
            <Events />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create"
        element={
          <ProtectedRoute>
            <GameContentCreator />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/team"
        element={
          <ProtectedRoute>
            <TeamPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sso-callback"
        element={<AuthenticateWithRedirectCallback />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
}

function App() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <TeamProvider>
            <AppRoutes />
          </TeamProvider>
        </QueryClientProvider>
      </BrowserRouter>
      <ToastContainer autoClose={4000} position="bottom-right" />
    </ClerkProvider>
  );
}

export default App;
