import { ContentRow, GameName } from "../types/games";
import { PICTURE_PUZZLE, TRIVIA, THIS_OR_THAT } from "../constants";

interface ValidationResult {
  isValid: boolean;
  error?: string;
}

export const validateContentRow = (
  row: ContentRow,
  gameType: GameName,
): ValidationResult => {
  switch (gameType) {
    case PICTURE_PUZZLE:
      if (!row.file) {
        return { isValid: false, error: "Image is required" };
      }
      if (!row.answer?.trim()) {
        return { isValid: false, error: "Answer is required" };
      }
      if (!row.clue?.trim()) {
        return { isValid: false, error: "Clue is required" };
      }
      break;

    case TRIVIA:
      if (!row.question?.trim()) {
        return { isValid: false, error: "Question is required" };
      }

      if (!row.answer?.trim()) {
        return { isValid: false, error: "Answer is required" };
      }

      const options = [row.option1, row.option2, row.option3, row.option4]
        .filter(Boolean)
        .map((opt) => opt?.trim());

      if (options.length < 2) {
        return { isValid: false, error: "At least 2 options are required" };
      }

      if (!options.includes(row.answer.trim())) {
        return {
          isValid: false,
          error: "Answer must match one of the options exactly",
        };
      }
      break;

    case THIS_OR_THAT:
      if (!row.choice_1?.trim() || !row.choice_2?.trim()) {
        return { isValid: false, error: "Both choices are required" };
      }
      break;
  }

  return { isValid: true };
};

export const validateBulkContent = (
  content: string,
  gameType: GameName,
): ValidationResult => {
  const lines = content.trim().split("\n").slice(1); // Skip header

  if (lines.length === 0) {
    return { isValid: false, error: "No content found" };
  }

  for (const [index, line] of lines.entries()) {
    const values = line.split(",").map((v) => v.trim());

    switch (gameType) {
      case "SLACK_THIS_OR_THAT":
        if (values.length !== 2) {
          return {
            isValid: false,
            error: `Line ${index + 1}: Expected 2 values (choice 1, choice 2)`,
          };
        }
        break;

      case "SLACK_TRIVIA":
        if (values.length < 3) {
          return {
            isValid: false,
            error: `Line ${index + 1}: Expected at least 3 values (question, answer, options)`,
          };
        }
        if (!values.slice(1).includes(values[1])) {
          return {
            isValid: false,
            error: `Line ${index + 1}: Answer must match one of the options`,
          };
        }
        break;
    }
  }

  return { isValid: true };
};
