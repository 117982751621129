import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useUserProfile } from "../hooks/useUserProfile";
import { useSearchParams } from "react-router-dom";
import { buildApiUrl } from "../api";
import Card from "../components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLock } from "@fortawesome/free-solid-svg-icons";

import SubscriptionSection from "../components/settings/subscriptionSection";
import SupportSection from "../components/settings/supportSection";
import AccountSection from "../components/settings/accountMgmt";
import { canAccessBilling } from "../lib/permissions";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function StripeWrapper({ children }: { children: React.ReactNode }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

interface SubscriptionStatus {
  isProcessing: boolean;
  error: string | null;
  isComplete: boolean;
}

function StripeHandler() {
  const stripe = useStripe();
  const api = useApi();
  const { refetchUserData } = useUserProfile();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const [status, setStatus] = useState<SubscriptionStatus>({
    isProcessing: false,
    error: null,
    isComplete: false,
  });

  useEffect(() => {
    const setupIntentSecret = searchParams.get("setup_intent_client_secret");
    const setupIntentId = searchParams.get("setup_intent");

    if (!stripe || status.isProcessing || status.isComplete) return;
    if (!setupIntentSecret || !setupIntentId) return;

    const processSetupIntent = async () => {
      setStatus((prev) => ({ ...prev, isProcessing: true }));

      try {
        // Clean URL immediately
        const newUrl = teamId
          ? `${window.location.pathname}?team=${teamId}`
          : window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);

        const { setupIntent } =
          await stripe.retrieveSetupIntent(setupIntentSecret);

        if (setupIntent?.status === "succeeded") {
          const response = await api.post(
            buildApiUrl("/v2/stripe/finalize-subscription", teamId),
            { setup_intent_id: setupIntentId },
          );

          if (response.data.status === "active") {
            await refetchUserData();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        setStatus((prev) => ({
          ...prev,
          error: "Failed to process subscription. Please contact support.",
        }));
      } finally {
        setStatus((prev) => ({
          ...prev,
          isProcessing: false,
          isComplete: true,
        }));
      }
    };

    processSetupIntent();
  }, [
    stripe,
    teamId,
    status.isProcessing,
    status.isComplete,
    api,
    refetchUserData,
    searchParams,
  ]);

  if (status.error) {
    return (
      <Card>
        <div className="p-6 flex flex-col items-center justify-center">
          <div className="text-brandRed text-xl mb-2">Something went wrong</div>
          <p className="text-gray-600 text-center mb-4">
            We couldn't process your subscription. Our team has been notified.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-700 transition-colors"
          >
            Refresh Page
          </button>
        </div>
      </Card>
    );
  }

  if (status.isProcessing) {
    return (
      <Card>
        <div className="p-8 flex flex-col items-center justify-center">
          <div className="animate-spin w-10 h-10 border-3 border-brand border-t-transparent rounded-full mb-4" />
          <h3 className="text-lg font-medium text-gray-800 mb-2">
            Finalizing Your Subscription
          </h3>
          <p className="text-gray-600 text-center">
            This will only take a moment. Please don't close this page.
          </p>
        </div>
      </Card>
    );
  }

  // When complete but no error, just return null
  // so the subscription section will show the updated subscription
  return null;
}

function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("team");
  const promoCode = searchParams.get("promo"); // Extract promo code
  const tabParam = searchParams.get("tab");
  const { userRole, isAccountOwner } = useUserProfile();

  // Check if the user can access the billing section
  const hasBillingAccess = canAccessBilling(userRole) || isAccountOwner;

  // Initialize active section from URL parameter if available
  // If user doesn't have billing access and tries to access billing tab, default to account
  const [activeSection, setActiveSection] = useState<
    "account" | "billing" | "support"
  >(
    tabParam === "billing" && hasBillingAccess
      ? "billing"
      : tabParam === "support"
        ? "support"
        : "account",
  );

  return (
    <StripeWrapper>
      <div className="container mx-auto">
        <div className="space-y-6">
          {/* Page header with navigation */}
          <div className="bg-white rounded-lg shadow">
            <div className="px-6 py-5">
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                <FontAwesomeIcon icon={faCog} className="mr-3 text-brand" />
                Settings
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                Manage your account, subscription, and support options
              </p>
            </div>
            <div className="border-t border-gray-200">
              <div className="flex">
                <button
                  className={`px-6 py-3 font-medium text-sm ${activeSection === "account" ? "text-brand border-b-2 border-brand" : "text-gray-500 hover:text-gray-700 border-b-2 border-transparent"}`}
                  onClick={() => {
                    setActiveSection("account");
                    // Update URL parameter
                    const newParams = new URLSearchParams(searchParams);
                    newParams.set("tab", "account");
                    setSearchParams(newParams);
                  }}
                >
                  Account
                </button>
                <button
                  className={`px-6 py-3 font-medium text-sm flex items-center ${activeSection === "billing" ? "text-brand border-b-2 border-brand" : "text-gray-500 hover:text-gray-700 border-b-2 border-transparent"} ${!hasBillingAccess ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={() => {
                    if (hasBillingAccess) {
                      setActiveSection("billing");
                      // Update URL parameter
                      const newParams = new URLSearchParams(searchParams);
                      newParams.set("tab", "billing");
                      setSearchParams(newParams);
                    }
                  }}
                  disabled={!hasBillingAccess}
                  title={
                    !hasBillingAccess
                      ? "Only admins can access billing settings"
                      : ""
                  }
                >
                  {!hasBillingAccess && (
                    <FontAwesomeIcon icon={faLock} className="mr-1 text-xs" />
                  )}
                  Billing & Subscription
                </button>
                <button
                  className={`px-6 py-3 font-medium text-sm ${activeSection === "support" ? "text-brand border-b-2 border-brand" : "text-gray-500 hover:text-gray-700 border-b-2 border-transparent"}`}
                  onClick={() => {
                    setActiveSection("support");
                    // Update URL parameter
                    const newParams = new URLSearchParams(searchParams);
                    newParams.set("tab", "support");
                    setSearchParams(newParams);
                  }}
                >
                  Support
                </button>
              </div>
            </div>
          </div>

          {/* Show StripeHandler at the top of the settings page for processing feedback */}
          <StripeHandler />

          {/* Conditionally render sections based on activeSection */}
          {activeSection === "account" && <AccountSection teamId={teamId} />}

          {activeSection === "billing" && hasBillingAccess && (
            <SubscriptionSection teamId={teamId} promoCode={promoCode} />
          )}

          {activeSection === "billing" && !hasBillingAccess && (
            <Card>
              <div className="p-6 text-center">
                <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center mx-auto mb-4">
                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-xl text-gray-500"
                  />
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Admin Access Required
                </h3>
                <p className="text-gray-600">
                  Only team administrators can access billing and subscription
                  settings.
                </p>
              </div>
            </Card>
          )}

          {activeSection === "support" && <SupportSection teamId={teamId} />}
        </div>
      </div>
    </StripeWrapper>
  );
}

export default Settings;
