import { useAuth } from "@clerk/clerk-react";
import { useApi } from "../../hooks/useApi";
import { buildApiUrl } from "../../api";
import { toast } from "react-toastify";
import Card from "../card";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { faSlack } from "@fortawesome/free-brands-svg-icons";

import * as Sentry from "@sentry/react";

interface ProfilePreferences {
  slack_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
}

interface AccountSectionProps {
  teamId: string | null;
}

const AccountSection = ({ teamId }: AccountSectionProps) => {
  const { signOut } = useAuth();
  const api = useApi();
  const [profilePrefs, setProfilePrefs] = useState<ProfilePreferences>({
    slack_notifications_enabled: true,
    email_notifications_enabled: true,
  });
  const [isSaving, setIsSaving] = useState(false);

  // Fetch profile preferences on component mount
  useEffect(() => {
    const fetchProfilePreferences = async () => {
      try {
        const response = await api.get(
          buildApiUrl("/v2/users/profile/preferences", teamId),
        );
        setProfilePrefs(response.data);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to load profile preferences", {
          position: "bottom-center",
        });
      }
    };

    fetchProfilePreferences();
  }, [teamId, api]);

  const handlePreferenceChange = async (key: keyof ProfilePreferences) => {
    // Create updated preferences
    const updatedPrefs = {
      ...profilePrefs,
      [key]: !profilePrefs[key],
    };

    // Update local state immediately for responsive UI
    setProfilePrefs(updatedPrefs);

    // Show saving indicator
    setIsSaving(true);

    try {
      // Make API call to update preferences
      await api.post(
        buildApiUrl("/v2/users/profile/preferences", teamId),
        updatedPrefs,
      );

      toast.success("Preference updated", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } catch (error) {
      // Revert local state on error
      setProfilePrefs(profilePrefs);

      Sentry.captureException(error);
      toast.error("Failed to update preference", {
        position: "bottom-center",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut({
        redirectUrl: "/profile?team=" + teamId,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to sign out", {
        position: "bottom-center",
      });
    }
  };

  const disconnectSlack = async () => {
    try {
      await api.post(buildApiUrl("/v2/slack/disconnect", teamId));
      window.location.href = "/";
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to disconnect Slack", {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="space-y-6">
      {/* Notification Preferences Card */}
      <Card>
        <div className="space-y-6">
          <div>
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-xl font-semibold">
                  Notification Preferences
                </h2>
                <p className="text-sm text-gray-600 mt-1">
                  Control how and when you receive notifications
                </p>
              </div>
              {isSaving && (
                <div className="flex items-center">
                  <span className="animate-spin mr-2 inline-block h-4 w-4 border-2 border-brand border-t-transparent rounded-full"></span>
                  <span className="text-sm text-gray-600">Saving...</span>
                </div>
              )}
            </div>
          </div>

          {/* Notification Channels */}
          <div className="space-y-4">
            <h3 className="text-md font-medium">Notification Channels</h3>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faSlack}
                    className="text-[#4A154B] mr-3"
                  />
                  <div>
                    <div className="font-medium">Slack Push Notifications</div>
                    <div className="text-sm text-gray-600">
                      Receive notifications in your Slack workspace
                    </div>
                  </div>
                </div>
                <div className="relative inline-block w-12 align-middle select-none">
                  <input
                    type="checkbox"
                    id="slack-toggle"
                    checked={profilePrefs.slack_notifications_enabled}
                    onChange={() =>
                      handlePreferenceChange("slack_notifications_enabled")
                    }
                    className="sr-only"
                  />
                  <label
                    htmlFor="slack-toggle"
                    className={`
                      block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out
                      ${profilePrefs.slack_notifications_enabled ? "bg-brand" : "bg-gray-300"}
                    `}
                  >
                    <span
                      className={`
                        block h-6 w-6 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out
                        ${profilePrefs.slack_notifications_enabled ? "translate-x-6" : "translate-x-0"}
                      `}
                    />
                  </label>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-gray-600 mr-3"
                  />
                  <div>
                    <div className="font-medium">Email Notifications</div>
                    <div className="text-sm text-gray-600">
                      Receive notifications via email
                    </div>
                  </div>
                </div>
                <div className="relative inline-block w-12 align-middle select-none">
                  <input
                    type="checkbox"
                    id="email-toggle"
                    checked={profilePrefs.email_notifications_enabled}
                    onChange={() =>
                      handlePreferenceChange("email_notifications_enabled")
                    }
                    className="sr-only"
                  />
                  <label
                    htmlFor="email-toggle"
                    className={`
                      block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out
                      ${profilePrefs.email_notifications_enabled ? "bg-brand" : "bg-gray-300"}
                    `}
                  >
                    <span
                      className={`
                        block h-6 w-6 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out
                        ${profilePrefs.email_notifications_enabled ? "translate-x-6" : "translate-x-0"}
                      `}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Notification policy information */}
          <div className="mt-6 pt-4 border-t border-gray-200">
            <p className="text-sm text-gray-600">
              Note: We will only send you necessary notifications based on your
              preferences. Some notifications related to account changes,
              security updates, and critical system information cannot be opted
              out of.
            </p>
          </div>
        </div>
      </Card>

      {/* Account Management Card */}
      <Card>
        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-semibold mb-4">Account Management</h2>
            <p className="text-sm text-gray-600 mt-1">
              Manage your account access and connections
            </p>
          </div>

          <div className="flex gap-x-4 justify-between">
            <button
              onClick={disconnectSlack}
              className="px-4 py-2 rounded-lg border hover:border-brand hover:text-brand transition-colors duration-200 font-medium outline-red border-brandRed text-brandRed/90"
            >
              Disconnect Slack
            </button>

            <button
              onClick={handleSignOut}
              className="px-4 py-2 rounded-lg text-slate-700 transition-all duration-200 border font-medium border-gray-800 hover:border-brand hover:text-brand"
            >
              Sign Out
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AccountSection;
