import { useState, useEffect, useCallback } from "react";
import { Minus, Plus, Tag } from "lucide-react";
import { buildApiUrl } from "../../api";

import { PromoData } from "../../types/promo";

import CTAButton from "../ctaButton";

import { useApi } from "../../hooks/useApi";

import * as Sentry from "@sentry/react";
import { MAX_SEATS } from "../../constants";

import {
  calculatePrice,
  formatPromoMessage,
  calculateAnnualSavings,
} from "../../lib/utils";

interface PlanSelectorProps {
  mode?: "plan" | "seats";
  selectedPlan: "monthly" | "annual";
  onSelect: (
    plan: "monthly" | "annual",
    seats: number,
    promoData: PromoData | null,
  ) => void;
  currentSeats?: number;
  minSeats?: number;
  promoCode?: string | null;
  isLoading?: boolean;
  teamId: string | null;
  // Whether to allow changing the billing interval
  allowIntervalChange?: boolean;
}

const PlanSelector = ({
  onSelect,
  currentSeats = 10,
  minSeats = 1,
  promoCode,
  isLoading = false,
  teamId,
  mode,
  selectedPlan = "monthly",
  allowIntervalChange = true,
}: PlanSelectorProps) => {
  const [seats, setSeats] = useState(Math.max(currentSeats, minSeats));
  const [planType, setPlanType] = useState<"monthly" | "annual">(selectedPlan);
  const [promoInput, setPromoInput] = useState(promoCode || "");
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [showPromoInput, setShowPromoInput] = useState(!!promoCode);
  const [promoData, setPromoData] = useState<PromoData | null>(null);

  const api = useApi();

  const validatePromo = useCallback(
    async (code: string) => {
      if (!code.trim()) return;

      setIsApplyingPromo(true);
      setPromoError("");

      try {
        const response = await api.post(
          buildApiUrl("/v2/stripe/validate-promo", teamId),
          { code: code.trim() },
        );
        setPromoData(response.data);
      } catch (err) {
        setPromoError("Could not apply promo, please reach out to support");
        setPromoData(null);
        Sentry.captureException(err);
      } finally {
        setIsApplyingPromo(false);
      }
    },
    [api, teamId],
  );

  // Auto-validate promo code on mount if exists
  useEffect(() => {
    if (promoCode) {
      validatePromo(promoCode);
    }
  }, [promoCode, validatePromo]);

  const handlePromoSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await validatePromo(promoInput);
  };

  const currentPrice = calculatePrice(planType === "annual", seats, promoData);

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold text-center mb-8">
        {mode === "seats" ? "Update Seat Count" : "Choose your plan"}
      </h2>

      {/* Billing Toggle */}
      {allowIntervalChange && !mode && (
        <div className="flex justify-center mb-8">
          <div className="bg-gray-100 p-1 rounded-full inline-flex">
            <button
              onClick={() => setPlanType("monthly")}
              disabled={isLoading}
              className={`px-6 py-2 rounded-full transition-all ${
                planType === "monthly"
                  ? "bg-white shadow-sm text-brand font-medium"
                  : "text-gray-600"
              } disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              Monthly
            </button>
            <button
              onClick={() => setPlanType("annual")}
              disabled={isLoading}
              className={`px-6 py-2 rounded-full transition-all ${
                planType === "annual"
                  ? "bg-white shadow-sm text-brand font-medium"
                  : "text-gray-600"
              } disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              Annual
              <span className="ml-1 text-xs text-brandGreen">
                {calculateAnnualSavings(7, 5 * 12)}
              </span>
            </button>
          </div>
        </div>
      )}

      {/* Seat Selection */}
      <div className="bg-gray-50 p-6 rounded-xl mb-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="font-medium">Team Size</h3>
            <p className="text-sm text-gray-500">
              {mode === "seats"
                ? "Set your total number of seats"
                : "How many people on your team?"}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setSeats(Math.max(seats - 1, minSeats))}
              disabled={isLoading}
              className="p-2 rounded-full hover:bg-gray-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Minus size={18} />
            </button>
            <span className="text-xl font-semibold w-12 text-center">
              {seats}
            </span>
            <button
              onClick={() => setSeats(Math.min(seats + 1, MAX_SEATS))}
              disabled={isLoading || seats >= MAX_SEATS}
              title={
                seats >= MAX_SEATS
                  ? "Maximum seat limit reached. Please contact sales for larger teams."
                  : ""
              }
              className="p-2 rounded-full hover:bg-gray-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Plus size={18} />
            </button>
          </div>
        </div>

        <div className="text-sm text-gray-600">
          ${currentPrice.toFixed(2)}/{planType === "annual" ? "year" : "month"}{" "}
          total
          <span className="mx-2">•</span>${planType === "annual" ? 5 : 7}
          /seat/month
        </div>

        {seats >= MAX_SEATS - 10 && (
          <div className="mt-2 text-xs text-amber-600">
            <p>
              {seats >= MAX_SEATS
                ? "Maximum seat limit reached. Please contact sales for larger teams."
                : `Approaching the maximum limit of ${MAX_SEATS} seats. Contact sales for larger teams.`}
            </p>
          </div>
        )}
      </div>

      {/* Promo Code */}
      {!promoData && !showPromoInput ? (
        <button
          onClick={() => setShowPromoInput(true)}
          disabled={isLoading}
          className="flex items-center text-brand text-sm mb-6 hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Tag size={16} className="mr-2" />
          Have a promo code?
        </button>
      ) : (
        <form onSubmit={handlePromoSubmit} className="mb-6">
          <div className="flex space-x-2">
            <input
              type="text"
              value={promoInput}
              onChange={(e) => setPromoInput(e.target.value)}
              disabled={isLoading || isApplyingPromo || !!promoData}
              className="flex-1 px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-brand/20 disabled:opacity-50 disabled:cursor-not-allowed"
              placeholder="Enter promo code"
            />
            {promoData ? (
              <CTAButton
                type="button"
                variant="secondary"
                size="sm"
                onClick={() => {
                  setPromoData(null);
                  setPromoInput("");
                }}
              >
                Remove
              </CTAButton>
            ) : (
              <CTAButton
                type="submit"
                variant="secondary"
                size="sm"
                isLoading={isApplyingPromo}
                loadingText="Applying..."
                disabled={!promoInput.trim()}
              >
                Apply
              </CTAButton>
            )}
          </div>
          {promoError && (
            <div className="text-brandRed/90 text-xs mt-2">{promoError}</div>
          )}
          {promoData && (
            <div className="text-brandGreen text-sm mt-2">
              {formatPromoMessage(promoData)}
            </div>
          )}
        </form>
      )}

      <CTAButton
        type="button"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => onSelect(planType, seats, promoData)}
        className="w-full"
      >
        Continue
      </CTAButton>
    </div>
  );
};

export default PlanSelector;
