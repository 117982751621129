import { useState } from "react";
import { buildApiUrl } from "../../api";
import * as axios from "axios";
import { differenceInHours } from "date-fns";

import { PromoData } from "../../types/promo";

import CheckoutForm from "./checkoutForm";
import CTAButton from "../ctaButton";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDice,
  faCog,
  faCalendar,
  faCrown,
  faPencil,
  faChartLine,
  faUserShield,
  faCheck,
  faUsers,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";

import Card from "../card";
import Modal from "../modal";
import PlanSelector from "./planSelector";

import { useApi } from "../../hooks/useApi";
import { useUserProfile } from "../../hooks/useUserProfile";

import { isActivePremium, isCancelling, isPremiumTrial } from "../../lib/utils";

import { DEFAULT_SEAT_COUNT_UPGRADE } from "../../constants";

import * as Sentry from "@sentry/react";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface SubscriptionSectionProps {
  teamId: string | null;
  promoCode?: string | null;
}

function SubscriptionSection({ teamId, promoCode }: SubscriptionSectionProps) {
  const [isModalOpen, setIsModalOpen] = useState(!!promoCode); // Opened automatically if the promo code is present (from url)
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual" | null>(
    null,
  );

  const [step, setStep] = useState<"plan" | "checkout" | "seats">("plan");
  const [selectedSeats, setSelectedSeats] = useState<number>(0);

  const [isPlanSelectionLoading, setIsPlanSelectionLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [promoData, setPromoData] = useState<PromoData | null>(null);

  const api = useApi();
  const {
    subscription,
    isLoading: isSubscriptionLoading,
    isError,
    refetchUserData,
  } = useUserProfile();

  const handleOpenModal = (mode: "plan" | "seats" = "plan") => {
    setIsModalOpen(true);
    setStep(mode);
    setError(null);

    // If we're adding seats, set the selected plan to match current subscription
    if (mode === "seats" && subscription?.billing_period) {
      const currentInterval = subscription.billing_period;
      setSelectedPlan(currentInterval);
    }

    // Auto-apply promo if present
    if (promoCode) {
      // Clean URL after reading promo
      const newUrl = teamId
        ? `${window.location.pathname}?team=${teamId}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientSecret(null);
    setSelectedPlan(null);
    setSelectedSeats(0);
    setStep("plan");
    setError(null);
  };

  const handleBackToPlan = () => {
    setStep("plan");
    setClientSecret(null);
    setError(null);
  };

  const handleSeatUpdate = async (
    _plan: "monthly" | "annual",
    seats: number,
    promoData: PromoData | null,
  ) => {
    // This function handles updating the total number of seats for the subscription
    // The 'seats' parameter represents the total seats requested, not additional seats
    setIsPlanSelectionLoading(true);
    setError(null);

    // Set the selected seats state to properly pass to checkout form
    setSelectedSeats(seats);
    // Store promo data for checkout form
    setPromoData(promoData);

    try {
      // Get the current billing interval from the subscription
      const currentInterval = subscription?.billing_period;
      const billingInterval = currentInterval;

      const response = await api.post(
        buildApiUrl("/v2/stripe/update-seats", teamId),
        {
          seats,
          promo_id: promoData?.id || null,
          billing_interval: billingInterval, // Pass the current billing interval
        },
      );

      setClientSecret(response.data.client_secret);
      setStep("checkout");
    } catch (err) {
      let errorMessage =
        "Failed to update seats. Please try again or contact support.";

      // Extract specific error message if available
      if (axios.isAxiosError(err)) {
        const detail = err.response?.data?.detail;
        const status = err.response?.status;

        if (detail) {
          // Use the specific error message from the backend
          errorMessage = detail;
        }

        // Check for specific race condition error codes
        if (
          status === 409 ||
          (detail && detail.includes("state has changed")) ||
          detail.includes("already being processed")
        ) {
          errorMessage =
            "Another seat update is in progress or the subscription has been modified. Please refresh and try again.";

          // Refresh data automatically after a short delay
          setTimeout(() => {
            handleCloseModal();
            refetchUserData?.();
          }, 3000);
        }
      }

      setError(errorMessage);
      Sentry.captureException(err);
    } finally {
      setIsPlanSelectionLoading(false);
    }
  };

  const handlePlanSelect = async (
    plan: "monthly" | "annual",
    seats: number,
    promoData: PromoData | null,
  ) => {
    setSelectedPlan(plan);
    setSelectedSeats(seats);
    setPromoData(promoData || null);
    setIsPlanSelectionLoading(true);
    setError(null);

    try {
      const payload = {
        plan,
        num_seats: seats,
        promo_id: promoData?.id || null,
      };

      const response = await api.post(
        buildApiUrl("/v2/stripe/setup-subscription", teamId),
        payload,
      );

      if (!response.data?.client_secret) {
        throw new Error("No client secret received from server");
      }

      setClientSecret(response.data.client_secret);
      setStep("checkout");
    } catch (error) {
      Sentry.captureException(error);

      let detail = "";
      if (axios.isAxiosError(error)) {
        detail = error.response?.data?.detail;
        setError(
          `Unable to set up payment, support has been notified${detail ? `: ${detail}` : ""}`,
        );
      } else {
        setError("Unable to set up payment, support has been notified");
      }

      setStep("plan");
    } finally {
      setIsPlanSelectionLoading(false);
    }
  };

  const handleCancel = async () => {
    if (!confirm("Are you sure you want to cancel your subscription?")) {
      return;
    }

    try {
      setLoading(true);
      await api.post("/v2/stripe/cancel-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      setError("Failed to cancel subscription, support has been notified");
    } finally {
      setLoading(false);
    }
  };

  const handleReactivate = async () => {
    if (!confirm("Are you sure you want to reactivate your subscription?")) {
      return;
    }

    try {
      setLoading(true);
      await api.post("/v2/stripe/reactivate-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      setError("Failed to reactivate subscription, support has been notified");
    } finally {
      setLoading(false);
    }
  };

  if (isSubscriptionLoading) {
    return <Card>Loading subscription information...</Card>;
  }

  if (isError) {
    return (
      <Card>
        Error loading subscription information. Please try again later.
      </Card>
    );
  }

  const premiumFeatures = [
    {
      icon: faDice,
      text: "Library of 6+ games",
      subtext: "New game added monthly, plus early access to new features",
    },
    {
      icon: faCalendar,
      text: "Schedule recurring events",
      subtext: "Keep your team engaged with automatic events",
    },
    {
      icon: faPencil,
      text: "Customize game content",
      subtext: "Create your own questions and challenges to match your team",
    },
    {
      icon: faChartLine,
      text: "Advanced insights",
      subtext: "Track participation and see how teams compare over time",
    },
    {
      icon: faCog,
      text: "Advanced game settings",
      subtext: "Fine-tune the experience with custom game rules and options",
    },
    {
      icon: faUserShield,
      text: "Account permissions",
      subtext: "Manage who can create games and content in your workspace",
    },
    {
      icon: faCalendar,
      text: "Calendar meeting integration",
      subtext: "Hook up your games to start with your meetings",
      comingSoon: true,
    },
  ];

  if (isSubscriptionLoading) {
    return (
      <Card>
        <div className="animate-pulse">
          <div className="h-6 w-32 bg-gray-200 rounded mb-4"></div>
          <div className="h-40 bg-gray-100 rounded"></div>
        </div>
      </Card>
    );
  }

  if (isError || !subscription) {
    return (
      <Card>
        <div className="text-gray-500 text-center py-8">
          Unable to load subscription information
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <h2 className="text-xl font-semibold mb-4">Subscription</h2>

        <div>
          {!isPremiumTrial(subscription) && !isActivePremium(subscription) && (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="bg-gray-100 p-2 rounded-full">
                    <FontAwesomeIcon
                      icon={faUsers}
                      className="text-gray-600 text-xl"
                    />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900">Free Plan</h3>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-center">
                    <span className="text-2xl font-bold text-brand">10</span>
                    <span className="text-sm text-gray-600">
                      seats included
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded-xl border border-gray-200 shadow-sm">
                <div className="mb-4">
                  <p className="text-sm font-medium text-gray-700">
                    Your free tier features:
                  </p>
                </div>

                <ul className="space-y-3">
                  <li className="flex items-start gap-3 p-2 rounded-lg transition-colors hover:bg-white/50">
                    <div className="mt-1">
                      <FontAwesomeIcon
                        icon={faGamepad}
                        className="w-4 text-gray-600"
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-700">
                          Access to 4 games
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">
                        Create and manage up to 4 different games for your team
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start gap-3 p-2 rounded-lg transition-colors hover:bg-white/50">
                    <div className="mt-1">
                      <FontAwesomeIcon
                        icon={faChartLine}
                        className="w-4 text-gray-600"
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-700">
                          Basic Insights
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">
                        View essential game statistics and team performance
                        metrics
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start gap-3 p-2 rounded-lg transition-colors hover:bg-white/50">
                    <div className="mt-1">
                      <FontAwesomeIcon
                        icon={faCog}
                        className="w-4 text-gray-600"
                      />
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-700">
                          Basic game customization
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">
                        Customize core game settings and parameters
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {isActivePremium(subscription) && !isPremiumTrial(subscription) && (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="bg-brand/10 p-2 rounded-full">
                    <FontAwesomeIcon
                      icon={faCrown}
                      className="text-brand text-xl"
                    />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900">
                    Premium Plan
                  </h3>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-center">
                    <span className="text-2xl font-bold text-brand">
                      {subscription.user_count}
                    </span>
                    <span className="text-sm text-gray-600">
                      active {subscription.user_count === 1 ? "seat" : "seats"}
                    </span>
                  </div>
                  <div className="h-10 w-px bg-gray-200" />
                  <button
                    onClick={() => handleOpenModal("seats")}
                    className={`text-brand hover:text-brand/80 text-sm font-medium ${
                      isCancelling(subscription)
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={isCancelling(subscription)}
                    title={
                      isCancelling(subscription)
                        ? "You cannot update seats for a subscription that is being canceled"
                        : "Update your seat count"
                    }
                  >
                    Update seats
                  </button>
                </div>
              </div>

              <div className="bg-gradient-to-br from-brand/5 to-brand/10 p-6 rounded-xl border border-brand/20 shadow-sm">
                <div className="mb-4">
                  <p className="text-sm font-medium text-gray-700">
                    Your active premium features:
                  </p>
                </div>

                <ul className="space-y-3">
                  {premiumFeatures.map((feature, index) => (
                    <li
                      key={index}
                      className={`flex items-start gap-3 p-2 rounded-lg transition-colors ${feature.comingSoon ? "bg-gray-50" : "hover:bg-white/50"}`}
                    >
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={feature.icon}
                          className={`w-4 ${feature.comingSoon ? "text-gray-400" : "text-brand"}`}
                        />
                      </div>
                      <div>
                        <div className="flex items-center gap-2">
                          <span
                            className={`font-medium ${feature.comingSoon ? "text-gray-500" : "text-gray-700"}`}
                          >
                            {feature.text}
                          </span>
                          {feature.comingSoon && (
                            <span className="text-xs bg-gray-100 text-gray-500 px-2 py-0.5 rounded-full">
                              Coming Soon
                            </span>
                          )}
                        </div>
                        <p
                          className={`text-sm ${feature.comingSoon ? "text-gray-400" : "text-gray-500"}`}
                        >
                          {feature.subtext}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex items-center justify-between pt-4 mt-4">
                <div className="text-sm text-gray-600">
                  {isCancelling(subscription) ? (
                    <span className="text-brandRed">
                      Your subscription will be cancelled at the end of the
                      current billing period
                    </span>
                  ) : (
                    <span>
                      Next billing date:{" "}
                      {subscription.current_period_end
                        ? new Date(
                            subscription.current_period_end,
                          ).toLocaleDateString()
                        : "--"}
                    </span>
                  )}
                </div>
                {isCancelling(subscription) ? (
                  <button
                    onClick={handleReactivate}
                    className={`text-brand font-medium hover:text-brand/80 ${loading ? "opacity-50 pointer-events-none" : ""}`}
                  >
                    {loading ? "Reactivating..." : "Reactivate subscription"}
                  </button>
                ) : (
                  <button
                    onClick={handleCancel}
                    className={`text-gray-800 font-medium hover:text-brandRed/80 ${loading ? "opacity-50 pointer-events-none" : ""}`}
                  >
                    {loading ? "Cancelling..." : "Cancel subscription"}
                  </button>
                )}
              </div>
            </div>
          )}

          {isPremiumTrial(subscription) && (
            <div className="flex items-center justify-between pb-4">
              <div className="flex items-center gap-3">
                <div className="bg-brand/10 p-2 rounded-full">
                  <FontAwesomeIcon
                    icon={faCrown}
                    className="text-brand text-xl"
                  />
                </div>
                <h3 className="text-xl font-bold text-gray-900">
                  Premium Trial
                </h3>
              </div>
              {subscription?.trial_end_date && (
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-center">
                    <span className="text-2xl font-bold text-black">
                      {Math.ceil(
                        differenceInHours(
                          new Date(subscription.trial_end_date),
                          new Date(),
                        ) / 24,
                      )}
                    </span>
                    <span className="text-sm text-gray-600">
                      days remaining
                    </span>
                  </div>
                  <div className="h-10 w-px bg-gray-200" />
                  <div className="flex flex-col items-end">
                    <span className="text-2xl font-bold text-brand">
                      {subscription.user_count}
                    </span>
                    <span className="text-sm text-gray-600">
                      {subscription.user_count === 1 ? "seat" : "seats"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {isPremiumTrial(subscription) && (
            <div className="mt-2 space-y-4">
              <div className="bg-gradient-to-br from-brand/5 to-brand/10 p-6 rounded-xl border border-brand/20 shadow-sm">
                <div className="mb-4">
                  <p className="text-sm font-medium text-gray-700">
                    Upgrade now to keep access to these premium features:
                  </p>
                </div>

                <ul className="space-y-3">
                  {premiumFeatures.map((feature, index) => (
                    <li
                      key={index}
                      className={`flex items-start gap-3 p-2 rounded-lg transition-colors ${feature.comingSoon ? "bg-gray-50" : "hover:bg-white/50"}`}
                    >
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={feature.icon}
                          className={`w-4 ${feature.comingSoon ? "text-gray-400" : "text-brand"}`}
                        />
                      </div>
                      <div>
                        <div className="flex items-center gap-2">
                          <span
                            className={`font-medium ${feature.comingSoon ? "text-gray-500" : "text-gray-700"}`}
                          >
                            {feature.text}
                          </span>
                          {feature.comingSoon && (
                            <span className="text-xs bg-gray-100 text-gray-500 px-2 py-0.5 rounded-full">
                              Coming Soon
                            </span>
                          )}
                        </div>
                        <p
                          className={`text-sm ${feature.comingSoon ? "text-gray-400" : "text-gray-500"}`}
                        >
                          {feature.subtext}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {(!isActivePremium(subscription) || isPremiumTrial(subscription)) && (
            <div className="mt-4 space-y-4">
              {!isPremiumTrial(subscription) && (
                <div className="bg-gradient-to-br from-brand/5 to-brand/10 p-6 rounded-xl border border-brand/20 shadow-sm">
                  <div className="mb-4">
                    <p className="text-sm font-medium text-gray-700">
                      Unlock premium features to supercharge your team building:
                    </p>
                  </div>

                  <ul className="space-y-3">
                    {premiumFeatures.map((feature, index) => (
                      <li
                        key={index}
                        className={`flex items-start gap-3 p-2 rounded-lg transition-colors ${feature.comingSoon ? "bg-gray-50" : "hover:bg-white/50"}`}
                      >
                        <div className="mt-1">
                          <FontAwesomeIcon
                            icon={feature.icon}
                            className={`w-4 ${feature.comingSoon ? "text-gray-400" : "text-brand"}`}
                          />
                        </div>
                        <div>
                          <div className="flex items-center gap-2">
                            <span
                              className={`font-medium ${feature.comingSoon ? "text-gray-500" : "text-gray-700"}`}
                            >
                              {feature.text}
                            </span>
                            {feature.comingSoon && (
                              <span className="text-xs bg-gray-100 text-gray-500 px-2 py-0.5 rounded-full">
                                Coming Soon
                              </span>
                            )}
                          </div>
                          <p
                            className={`text-sm ${feature.comingSoon ? "text-gray-400" : "text-gray-500"}`}
                          >
                            {feature.subtext}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div>
                <CTAButton
                  onClick={() => handleOpenModal("plan")}
                  fullWidth
                  size="lg"
                  className={`bg-gradient-cta hover:bg-gradient-cta-hover shadow-cta hover:shadow-cta-hover transition-all duration-300 ${isPremiumTrial(subscription) ? "font-bold" : ""}`}
                >
                  {isPremiumTrial(subscription)
                    ? "Keep Premium Access - Upgrade Now"
                    : "Upgrade to Premium"}
                </CTAButton>
                <div className="mt-3 flex items-center justify-center gap-3 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-green-500"
                    />
                    <span>No commitment</span>
                  </div>
                  <div className="h-4 w-px bg-gray-300" />
                  <div className="flex items-center gap-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-green-500"
                    />
                    <span>Cancel anytime</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size={step === "checkout" ? "large" : "default"}
      >
        {step === "plan" ? (
          <>
            <PlanSelector
              selectedPlan={"monthly"}
              onSelect={handlePlanSelect}
              currentSeats={DEFAULT_SEAT_COUNT_UPGRADE}
              isLoading={isPlanSelectionLoading}
              teamId={teamId}
              promoCode={promoCode}
            />
            {error && (
              <div className="px-6 pb-4">
                <div className="text-brandRed text-sm bg-red-50 p-3 rounded-lg">
                  {error}
                </div>
              </div>
            )}
          </>
        ) : step === "seats" && subscription ? (
          <>
            <PlanSelector
              mode="seats"
              onSelect={handleSeatUpdate}
              currentSeats={subscription.user_count}
              minSeats={subscription.user_count}
              isLoading={isPlanSelectionLoading}
              promoCode={promoCode}
              teamId={teamId}
              selectedPlan={subscription.billing_period}
            />
            {error && (
              <div className="px-6 pb-4">
                <div className="text-brandRed text-sm bg-red-50 p-3 rounded-lg">
                  {error}
                </div>
              </div>
            )}
          </>
        ) : clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm
              onBack={handleBackToPlan}
              plan={selectedPlan!}
              teamId={teamId}
              seats={selectedSeats}
              promoData={promoData}
            />
          </Elements>
        ) : (
          <div className="p-6 text-center">
            <div
              className="animate-spin inline-block w-6 h-6 border-2 border-current border-t-transparent text-brand rounded-full"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-2 text-gray-600">Setting up payment...</p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default SubscriptionSection;
