import { SlackUser, Subscription } from "../types/user";
import { PromoData } from "../types/promo";
import { SEAT_PRICE_MONTH, SEAT_PRICE_MONTH_ANNUAL } from "../constants";

const generateSlackDeepLink = (slackUser?: SlackUser) => {
  if (!slackUser) return "";

  const { app_id, team_id } = slackUser;

  return `slack://app?team=${team_id}&id=${app_id}&tab=home`;
};

// Helper functions
// Check if a subscription has premium features enabled
const isActivePremium = (subscription: Subscription | undefined) => {
  if (!subscription) return false;
  if (isCancelling(subscription)) return true;

  const hasPremiumAccess = ["active", "trial"].includes(subscription.status);

  return hasPremiumAccess && subscription.tier.tier_type === "Premium";
};

// Check if a subscription is in trial period
const isPremiumTrial = (subscription: Subscription | undefined) =>
  subscription?.status === "trial";

const isCancelling = (subscription: Subscription | undefined) => {
  if (!subscription) {
    return false;
  }
  return (
    subscription?.status === "canceling" ||
    subscription?.cancel_at_period_end === true
  );
};

// Update status text display
// Format subscription status text for display
const getStatusText = (subscription: Subscription | undefined) => {
  if (!subscription) return "--";

  const formatPeriod = (period: string) =>
    period.charAt(0).toUpperCase() + period.slice(1);

  const formatSeats = (count: number) =>
    `${count} seat${count === 1 ? "" : "s"}`;

  const baseInfo = {
    period: formatPeriod(subscription.billing_period),
    seats: formatSeats(subscription.user_count),
  };

  if (subscription.status === "canceling") {
    return `Cancels at period end • ${baseInfo.period} • ${baseInfo.seats}`;
  }

  if (subscription.status === "trial") {
    const trialEnd = new Date(subscription.trial_end_date || "");
    return `Trial ends ${trialEnd.toLocaleDateString()} • ${baseInfo.seats}`;
  }

  if (isActivePremium(subscription)) {
    return `Current Plan • ${baseInfo.period} • ${baseInfo.seats}`;
  }

  return `From $${SEAT_PRICE_MONTH}/seat per month or $${SEAT_PRICE_MONTH_ANNUAL}/seat per year`;
};

const calculatePrice = (
  isAnnual: boolean,
  seats: number,
  promoData: PromoData | null,
) => {
  // Calculate monthly base price first
  const monthlyBasePrice =
    seats * (isAnnual ? SEAT_PRICE_MONTH_ANNUAL : SEAT_PRICE_MONTH);

  // For annual, multiply by 12 months
  const basePrice = isAnnual ? monthlyBasePrice * 12 : monthlyBasePrice;

  // Apply discount if promo exists
  if (promoData?.discount) {
    return basePrice * ((100 - promoData.discount.percent_off) / 100);
  }

  return basePrice;
};

const formatPromoMessage = (promo: PromoData) => {
  const expiresAt = new Date(promo.expires_at);
  const discount = promo.discount;

  let message = `${discount.percent_off}% off`;

  if (discount.duration === "repeating" && discount.duration_months) {
    message += ` for ${discount.duration_months} month${discount.duration_months > 1 ? "s" : ""}`;
  } else if (discount.duration === "forever") {
    message += " forever";
  } else if (discount.duration === "once") {
    message += " for first month";
  }

  if (expiresAt) {
    message += ` (expires ${expiresAt.toLocaleDateString()})`;
  }

  return message;
};

const calculateAnnualSavings = (
  monthlyPrice: number,
  annualPrice: number,
): string => {
  const effectiveMonthlyPrice = annualPrice / 12;
  const savings = ((monthlyPrice - effectiveMonthlyPrice) / monthlyPrice) * 100;

  // Round to the nearest 5 for better UX
  const roundedSavings = Math.round(savings / 5) * 5;

  return `Save ~${roundedSavings}%`;
};

export {
  generateSlackDeepLink,
  isActivePremium,
  isPremiumTrial,
  isCancelling,
  getStatusText,
  calculatePrice,
  formatPromoMessage,
  calculateAnnualSavings,
};
