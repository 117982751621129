import { Upload } from "lucide-react";
import { ContentRow, GameName } from "../../types/games";
import {
  CUSTOMIZATION_GAME_CONFIGS as GAME_CONFIGS,
  PICTURE_PUZZLE,
} from "../../constants";
import { validateContentRow } from "../../lib/forms";

import { useApi } from "../../hooks/useApi";

import { toast } from "react-toastify";

interface PicturePuzzleCreateFormProps {
  contentRows: ContentRow[];
  setContentRows: React.Dispatch<React.SetStateAction<ContentRow[]>>;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  isLoading: boolean;
  scope: string;
  selectedChannel: string;
  selectedCategory: string;
  channels: Array<{ id: string; name: string }>;
  categories: Array<{ id: string; name: string; human_name?: string }>;
  selectedGame: GameName | null;
  teamId: string | null;
}

export const PicturePuzzleCreateForm = ({
  contentRows,
  setContentRows,
  handleSubmit,
  isLoading,
  scope,
  selectedChannel,
  selectedCategory,
  channels,
  categories,
  selectedGame,
  teamId,
}: PicturePuzzleCreateFormProps) => {
  const api = useApi();

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="pt-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Picture Puzzles</h3>
          <button
            type="button"
            onClick={() => setContentRows([...contentRows, { id: Date.now() }])}
            className="px-3 py-1 text-sm bg-brand bg-opacity-10 text-brand rounded-md hover:bg-opacity-20 transition-colors duration-150 flex items-center gap-1"
          >
            + Add Puzzle
          </button>
        </div>

        {contentRows.map((row, index) => (
          <div
            key={row.id}
            className="space-y-4 mb-6 pb-6 border-b border-gray-200 last:border-b-0"
          >
            <div className="flex justify-between items-start">
              <h4 className="text-sm font-medium text-gray-700">
                Puzzle {index + 1}
              </h4>
              {contentRows.length > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    const newRows = contentRows.filter((_, i) => i !== index);
                    setContentRows(newRows);
                  }}
                  className="px-3 py-1 text-sm bg-red-50 text-red-600 rounded-md hover:bg-red-100 hover:text-red-700 transition-colors duration-150 flex items-center gap-1"
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  Remove
                </button>
              )}
            </div>

            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
              <input
                type="file"
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (!file) return;

                  // Validate file type
                  if (!file.type.startsWith("image/")) {
                    toast.error("Please select an image file");
                    return;
                  }

                  // Validate file size (5MB)
                  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
                  if (file.size > MAX_FILE_SIZE) {
                    toast.error("Image size must be less than 5MB");
                    return;
                  }

                  try {
                    // Create form data for upload
                    const formData = new FormData();
                    formData.append("file", file);

                    // Upload image
                    const response = await api.post(
                      `/v2/games/library/upload-image?team=${teamId}`,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      },
                    );

                    const { image_id, url } = response.data;

                    // Update content row with file preview and image_id
                    const newRows = [...contentRows];
                    newRows[index] = { ...row, file, image_id, image_url: url };
                    setContentRows(newRows);

                    toast.success("Image uploaded successfully");
                  } catch (error) {
                    toast.error("Failed to upload image");
                    console.error("Image upload error:", error);
                  }
                }}
                className="hidden"
                id={`image-upload-${index}`}
              />
              <label
                htmlFor={`image-upload-${index}`}
                className="flex flex-col items-center cursor-pointer"
              >
                {row.file ? (
                  <div className="flex flex-col items-center">
                    <img
                      src={row.image_url || URL.createObjectURL(row.file)}
                      alt="Preview"
                      className="w-32 h-32 object-cover rounded-lg mb-2"
                    />
                    <span className="text-sm text-gray-600">
                      {row.file.name}
                    </span>
                    <span className="text-xs text-gray-600 mt-1">
                      {(row.file.size / (1024 * 1024)).toFixed(2)}MB
                    </span>
                    {row.image_id && (
                      <span className="text-xs text-green-500 mt-1">
                        ✓ Uploaded
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <Upload className="w-12 h-12 text-gray-400" />
                    <span className="mt-2 text-sm text-gray-600">
                      Upload puzzle image
                    </span>
                    <span className="text-xs text-gray-600 mt-1">
                      Max size: 5MB
                    </span>
                  </>
                )}
              </label>
            </div>

            {GAME_CONFIGS[PICTURE_PUZZLE].fields.map((field, idx) => (
              <div key={field}>
                <label className="block text-sm font-medium text-gray-700">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </label>
                <input
                  type="text"
                  placeholder={GAME_CONFIGS[PICTURE_PUZZLE].placeholders[idx]}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand"
                  value={row[field] || ""}
                  onChange={(e) => {
                    const newRows = [...contentRows];
                    newRows[index] = { ...row, [field]: e.target.value };
                    setContentRows(newRows);
                  }}
                />
                {field === "answer" && (
                  <div className="mt-1 p-2 bg-brand/5 rounded">
                    <p className="text-xs text-gray-600">
                      <strong>Optional</strong> Use square brackets to reveal
                      specific characters. For example, if you enter{" "}
                      <span className="font-mono bg-white px-1 rounded">
                        The [P]rincess [B]ride
                      </span>
                      , players will see{" "}
                      <span className="font-mono bg-white px-1 rounded">
                        The P_______ B____
                      </span>
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}

        <div className="flex items-center gap-4 mt-4 justify-end">
          <span className="text-sm text-gray-600">
            Adds to{" "}
            {scope === "channel"
              ? `#${channels.find((c) => c.id === selectedChannel)?.name}`
              : "workspace"}
            {selectedCategory
              ? ` • ${categories.find((c) => c.id === selectedCategory)?.human_name || categories.find((c) => c.id === selectedCategory)?.name}`
              : " • Select a category"}
          </span>

          <button
            type="submit"
            disabled={
              isLoading ||
              (scope === "channel" && !selectedChannel) ||
              !selectedCategory ||
              contentRows.some((row) =>
                selectedGame
                  ? !validateContentRow(row, selectedGame).isValid
                  : true,
              )
            }
            className="px-4 py-2 bg-brand text-white rounded-md hover:bg-brandCompliment disabled:opacity-50 min-w-[80px] text-center"
          >
            {isLoading ? "Adding..." : "Add"}
          </button>
        </div>
      </div>
    </form>
  );
};
